






















































import { Component, Vue } from "vue-property-decorator";
import AuthService from '@/App/Services/AuthService';
import LoginLayout from "@/views/Auth/LoginLayout.vue";
import LogoIcon from '../../assets/svgs/logo.svg';
import { AxiosError, AxiosResponse } from "axios";

@Component({
    components: {
        LoginLayout,
        'logo-icon': LogoIcon,
    },
})
export default class Login extends Vue {
    username: string = '';
    password: string = '';
    passwordIncorrect: boolean = false;
    loading: boolean = false;
    errorOccured: boolean = false;

    login() {
        this.errorOccured = false;
        this.passwordIncorrect = false;
        this.loading = true;

        AuthService.instance.login(this.username, this.password)
            .then(() => {
                this.$router.push({
                    name: 'dashboard',
                });
            })
            .catch((response: AxiosError) => {
                // TODO some error catching for logins
                if (response.response !== undefined && response.response.status === 401) {
                    this.passwordIncorrect = true;
                } else {
                    this.errorOccured = true;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
