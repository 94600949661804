
































import StatusMenuContacts from '@/components/StatusMenuContacts.vue';
import TabsClients from '@/components/TabsClients.vue';
import TabSingle from '@/components/TabSingle.vue';
import { Component, Vue } from "vue-property-decorator";
import HttpService from "@/App/Services/HttpService";
import InfiniteLoading from "vue-infinite-loading";
import ClientContactInfo from "@/components/ClientContactInfo.vue";

@Component({
    components: {
        'client-contact-info': ClientContactInfo,
        'status-menu-contacts': StatusMenuContacts,
        'tabs-clients': TabsClients,
        'tab-single': TabSingle,
        'infinite-loading': InfiniteLoading,
    }
})
export default class ClientContacts extends Vue {
    search: string = '';
    contacts: object[] = [];
    page = 1;

    async getContacts() {
        return HttpService.instance.get('/contacts', {
            search: this.search,
            page: this.page
        }).then(({ data }) => data);
    }

    searchContacts() {
        this.contacts = [];
        this.page = 1;
        // @ts-ignore
        this.$refs.infiniteLoading.stateChanger.reset();
    }

    closeTab(index: Number) {
        document.body.classList.remove('hidden-overflow');
        // @ts-ignore
        this.$refs['clients' + index][0].selected = false;
        // @ts-ignore
        this.$refs.tabClients.detailsActive = false;
    }

    infiniteHandler($state: any) {
        this.getContacts().then((response) => {
            response.data.map((contact: object) => this.contacts.push(contact));

            if (response.data.length === 0) {
                return $state.complete();
            }
            $state.loaded();

            this.page++;
        });
    }
};
