




































































































import HttpService from "@/App/Services/HttpService";
import ClearFiltersSvg from '@/assets/svgs/clear-filters.svg';
import DatePicker from "@/components/DatePicker.vue";
import Dropdown from "@/components/Dropdown.vue";
import Graph from "@/components/Graph.vue";
import EmployeeGroup from "@/components/hour-control/EmployeeGroup.vue";
import ProjectGroup from "@/components/hour-control/ProjectGroup.vue";
import { ProjectObject } from "@/types/timeTrackingTypes";
import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        'project-group': ProjectGroup,
        'graph': Graph,
        'datepicker': DatePicker,
        'dropdown': Dropdown,
        'clear-filters-svg': ClearFiltersSvg,
        'employee-group': EmployeeGroup,
    }
})
export default class HourControl extends Vue {
    @Prop({ type: String }) status: string | undefined;
    @Prop({ type: Boolean, default: () => false }) isInbox!: boolean;
    @Prop({ type: Boolean, default: () => false }) shouldIgnoreDateFilter!: boolean;

    filterStatuses = [
        {
            value: 'open',
            label: 'Open uren'
        },
        {
            value: 'missing',
            label: 'Missende uren'
        },
        {
            value: 'submitted',
            label: 'Controle planning'
        },
        {
            value: 'accepted',
            label: 'Controle administratie'
        },
        {
            value: 'checked',
            label: 'Klaar voor kostenstaat'
        },
        {
            value: 'in_progress',
            label: 'Te factureren'
        },
        {
            value: 'billed',
            label: 'Gefactureerd'
        },
    ];
    filterClients: Array<{ value: any, label: string }> = [];
    filterProjects: Array<{ value: any, label: string }> = [];
    filterEmployees: Array<{ value: any, label: string }> = [];

    mockGraphData: any = [];

    totalHours: number = 0;

    fromDate: moment.Moment | null = null;
    toDate: moment.Moment | null = null;
    selectedStatuses: Array<number> = [];
    selectedProjects: Array<number> = [];
    selectedClients: Array<number> = [];
    selectedEmployees: Array<number> = [];

    searching = false;
    mockTimeTrackings = {};
    page = 1;

    $refs !: {
        datepickerFilter: any,
        statusFilter: any,
        projectsFilter: any,
        clientFilter: any,
        employeeFilter: any,
    };

    get filtersAvailable() {
        const hasDateFilter = this.fromDate !== null && this.toDate !== null;
        const hasStatusFilter = this.selectedStatuses.length > 0;
        const hasProjectsFilter = this.selectedProjects.length > 0;
        const hasClientsFilter = this.selectedClients.length > 0;
        const hasEmployeeFilter = this.selectedEmployees.length > 0;
        return hasDateFilter || hasStatusFilter || hasProjectsFilter || hasClientsFilter || hasEmployeeFilter;
    }

    private static makeDataEmployeeBased(item: any, data: { [p: string]: ProjectObject }) {
        const userId = item.user_id;
        if (!Object.prototype.hasOwnProperty.call(data, userId)) {
            data[userId] = {
                projectId: item.project_id,
                clientId: item.client_id,
                projectNumber: item.project_number,
                clientName: item.client_name,
                projectTitle: item.project_description,
                employeeName: item.employee_name,
                timeTrackings: {},
            };
        }

        if (!Object.prototype.hasOwnProperty.call(data[userId].timeTrackings, item.date)) {
            data[userId].timeTrackings[item.date] = [];
        }

        data[userId].timeTrackings[item.date].push({
            id: item.id,
            workOrderId: item.work_order_id,
            userId: item.user_id,
            title: item.title,
            subtitle: item.project_number + ' | ' + item.client_name,
            projectNumberClient: item.project_number_client,
            status: item.status,
            date: moment(item.date),
            costStateHours: {
                start: item.cost_state_start_time === null ? null : moment(item.cost_state_start_time, 'HH:mm:ss'),
                end: item.cost_state_end_time === null ? null : moment(item.cost_state_end_time, 'HH:mm:ss'),
                break: moment.utc(item.cost_state_break * 1000),
            },
            rewardableHours: {
                start: item.rewardable_hours_start_time === null ? null : moment(item.rewardable_hours_start_time, 'HH:mm:ss'),
                end: item.rewardable_hours_end_time === null ? null : moment(item.rewardable_hours_end_time, 'HH:mm:ss'),
                break: moment.utc(item.rewardable_hours_break * 1000),
            },
        });
    }

    private static makeDataProjectBased(item: any, data: { [p: string]: ProjectObject }) {
        const projectNumber = item.project_number;
        if (!Object.prototype.hasOwnProperty.call(data, projectNumber)) {
            data[projectNumber] = {
                projectId: item.project_id,
                clientId: item.client_id,
                projectNumber: item.project_number,
                clientName: item.client_name,
                employeeName: item.employee_name,
                projectTitle: item.project_description,
                timeTrackings: {},
            };
        }

        if (!Object.prototype.hasOwnProperty.call(data[projectNumber].timeTrackings, item.date)) {
            data[projectNumber].timeTrackings[item.date] = [];
        }

        data[projectNumber].timeTrackings[item.date].push({
            id: item.id,
            workOrderId: item.work_order_id,
            userId: item.user_id,
            title: item.title,
            subtitle: item.employee_name,
            projectNumberClient: item.project_number_client,
            status: item.status,
            date: moment(item.date),
            costStateHours: {
                start: item.cost_state_start_time === null ? null : moment(item.cost_state_start_time, 'HH:mm:ss'),
                end: item.cost_state_end_time === null ? null : moment(item.cost_state_end_time, 'HH:mm:ss'),
                break: moment.utc(item.cost_state_break * 1000),
            },
            rewardableHours: {
                start: item.rewardable_hours_start_time === null ? null : moment(item.rewardable_hours_start_time, 'HH:mm:ss'),
                end: item.rewardable_hours_end_time === null ? null : moment(item.rewardable_hours_end_time, 'HH:mm:ss'),
                break: moment.utc(item.rewardable_hours_break * 1000),
            },
        });
    }

    mounted() {
        this.reloadData();
        HttpService.instance.get('projects')
            .then(value => {
                for (const project of value.data.data) {
                    this.filterProjects.push({
                        value: project.id,
                        label: `${project.project_number} | ${project.name}`
                    });
                }
            });
        HttpService.instance.get('clients')
            .then(value => {
                this.filterClients = [];
                for (const client of value.data.data) {
                    this.filterClients.push({ value: client.id, label: client.name });
                }
            });
        this.filterEmployees = [];
        HttpService.instance.get('employees/filter', { paginate: false })
            .then(value => {
                for (const employee of value.data.data) {
                    this.filterEmployees.push({ value: employee.user_id, label: employee.full_name });
                }
            });
        HttpService.instance.get('freelancers/filter', { paginate: false })
            .then(value => {
                for (const freelancer of value.data.data) {
                    this.filterEmployees.push({ value: freelancer.user_id, label: freelancer.full_name });
                }
            });
    }

    getTimeTrackings() {
        this.searching = true;

        HttpService.instance.get('time-trackings', this.queryParams)
            .then(value => {
                const data: { [projectNumber: string]: ProjectObject } = {};

                if (this.$route.name === 'hour-control-employees') {
                    value.data.data.forEach((item: any) => {
                        HourControl.makeDataEmployeeBased(item, data);
                    });
                } else {
                    value.data.data.forEach((item: any) => {
                        HourControl.makeDataProjectBased(item, data);
                    });
                }
                this.mockTimeTrackings = data;
            })
            .finally(() => {
                this.searching = false;
            });
    }

    getGraphData() {
        HttpService.instance.get('time-trackings/hours', this.queryParams)
            .then(value => {
                this.totalHours = value.data.data.total;
                this.buildGraphData(value.data.data.statuses);
            });
    }

    buildGraphData(statuses: { [key: string]: string }) {
        this.mockGraphData = [];
        for (const key in statuses) {
            const graphObject: { percentage: string, total: string, icon: string, color: string, label: string } = {
                percentage: ((parseFloat(statuses[key]) / this.totalHours) * 100).toFixed(0),
                total: statuses[key],
                icon: '',
                color: '',
                label: '',
            };

            switch (key) {
                case'missing':
                    graphObject.icon = 'missing-status-svg';
                    graphObject.color = 'CC0000';
                    graphObject.label = 'Missende uren';
                    break;
                case'submitted':
                    graphObject.icon = 'submitted-status-svg';
                    graphObject.color = '00BFFF';
                    graphObject.label = 'Controle planning';
                    break;
                case'accepted':
                    graphObject.icon = 'accepted-status-svg';
                    graphObject.color = '005A7B';
                    graphObject.label = 'Controle administratie';
                    break;
                case'checked':
                    graphObject.icon = 'checked-status-svg';
                    graphObject.color = 'FF8C00';
                    graphObject.label = 'Klaar voor kostenstaat';
                    break;
                case'in_progress':
                    graphObject.icon = 'open-status-svg';
                    graphObject.color = 'FFC125';
                    graphObject.label = 'Te factureren';
                    break;
                case'open':
                default:
                    graphObject.icon = 'open-status-svg';
                    graphObject.color = 'B0E0E6';
                    graphObject.label = 'Open uren';
                    break;
            }
            this.mockGraphData.push(graphObject);
        }
    }

    resetFilters() {
        this.$refs.datepickerFilter.resetFilter();
        this.$refs.statusFilter.resetFilter();
        this.$refs.projectsFilter.resetFilter();
        this.$refs.clientFilter.resetFilter();
        this.$refs.employeeFilter.resetFilter();
        this.toDate = null;
        this.fromDate = null;
        this.applyFilters();
    }

    dateSelected(value: { from: moment.Moment | null, to: moment.Moment | null }) {
        this.fromDate = value.from;
        this.toDate = value.to;
    }

    applyFilters() {
        this.$refs.clientFilter.close();
        this.$refs.datepickerFilter.close();
        this.$refs.statusFilter.close();
        this.$refs.projectsFilter.close();
        this.$refs.employeeFilter.close();
        this.reloadData();
    }

    reloadData() {
        this.page = 0;
        this.mockTimeTrackings = {};
        this.loadData();
    }

    loadData() {
        this.page++;
        this.getGraphData();
        this.getTimeTrackings();
    }

    get queryParams() {
        const params: { [key: string]: any } = {
            shouldIgnoreDateFilter: this.isInbox,
        };

        if (this.isInbox) {
            params.status = this.status;

            return params;
        }

        if (this.fromDate !== null) {
            params.from = this.fromDate.format('YYYY-MM-DD')
        }
        if (this.toDate !== null) {
            params.to = this.toDate.format('YYYY-MM-DD')
        }
        if (this.selectedStatuses.length > 0) {
            params.status = this.selectedStatuses.join(',');
        }
        if (this.selectedProjects.length > 0) {
            params.project = this.selectedProjects.join(',');
        }
        if (this.selectedClients.length > 0) {
            params.client = this.selectedClients.join(',');
        }
        if (this.selectedEmployees.length > 0) {
            params.employee_user = this.selectedEmployees.join(',');
        }

        return params;
    }
}
