







































import { Component, Prop, Vue } from "vue-property-decorator";
import { ProjectObject } from "@/types/timeTrackingTypes";
import moment from "moment";
import ProjectRow from "@/components/hour-control/ProjectRow.vue";

@Component({
    components: {
        'project-row': ProjectRow,
    },
})
export default class EmployeeGroup extends Vue {
    @Prop() pg!: ProjectObject;
    @Prop() type!: string;

    get projectLink() {
        return process.env.VUE_APP_ERP_URL + '/projecten/' + this.pg.projectId;
    }

    get clientLink() {
        return process.env.VUE_APP_ERP_URL + '/opdrachtgevers/' + this.pg.clientId;
    }

    timeTrackingDate(date: string) {
        moment.locale(navigator.language);
        return moment(date).format('LL');
    }
}
