



































































































































import TimeTrackingStatuses, { getTimeTrackingByStatus } from "@/App/Entities/TimeTrackingStatuses";
import AuthService from "@/App/Services/AuthService";
import HttpService from "@/App/Services/HttpService";
import CheckSvg from '@/assets/svgs/check.svg';
import AcceptedStatusSvg from '@/assets/svgs/status-accepted.svg';
import BilledStatusSvg from '@/assets/svgs/status-billed.svg';
import CheckedStatusSvg from '@/assets/svgs/status-checked.svg';
import InProgressStatusSvg from '@/assets/svgs/status-in-progress.svg';
import MissingStatusSvg from '@/assets/svgs/status-missing.svg';
import OpenStatusSvg from '@/assets/svgs/status-open.svg';
import SubmittedStatusSvg from '@/assets/svgs/status-submitted.svg';
import { TimeTrackingType } from "@/types/timeTrackingTypes";
import { AxiosResponse } from "axios";
import moment from "moment";
// @ts-ignore
import MaskedInput from "vue-masked-input/src/MaskedInput";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        'masked-input': MaskedInput,
        'open-status-svg': OpenStatusSvg,
        'missing-status-svg': MissingStatusSvg,
        'submitted-status-svg': SubmittedStatusSvg,
        'accepted-status-svg': AcceptedStatusSvg,
        'checked-status-svg': CheckedStatusSvg,
        'in-progress-status-svg': InProgressStatusSvg,
        'billed-status-svg': BilledStatusSvg,
        'check-svg': CheckSvg,
    }
})
export default class ProjectRow extends Vue {
    @Prop() timeTracking!: TimeTrackingType;
    deleted = false;
    status!: number;
    projectNumberClient!: string;
    rewardableHoursStart!: string | null;
    rewardableHoursEnd!: string | null;
    rewardableHoursBreak!: string | null;
    costStateHoursStart!: string | null;
    costStateHoursEnd !: string | null;
    costStateHoursBreak!: string | null;
    rewardableTotal: string = '';
    costStateTotal: string = '';
    differenceTotal: string = '';

    get showTotalDifference() {
        return this.differenceTotal !== '' && this.differenceTotal !== '+00:00' && !this.differenceTotal.includes('NaN');
    }

    created() {
        this.status = this.timeTracking.status;
        this.projectNumberClient = this.timeTracking.projectNumberClient;
        this.rewardableHoursStart = this.timeTracking.rewardableHours.start?.format('HH:mm') || null;
        this.rewardableHoursEnd = this.timeTracking.rewardableHours.end?.format('HH:mm') || null;
        this.rewardableHoursBreak = this.timeTracking.rewardableHours.break?.format('HH:mm') || null;
        this.costStateHoursStart = this.timeTracking.costStateHours.start?.format('HH:mm') || null;
        this.costStateHoursEnd = this.timeTracking.costStateHours.end?.format('HH:mm') || null;
        this.costStateHoursBreak = this.timeTracking.costStateHours.break?.format('HH:mm') || null;
        this.updateRewardableTotal();
        this.updateCostStateTotal();
    }

    updateRow(apiField: '' | 'project-number-client' | 'rewardable-hours' | 'cost-state-hours' = '') {
        if (this.hasChanged()) {
            let shouldAskConfirmation = false;
            if (apiField === 'project-number-client'
                && !this.isSubmitted
                && !this.isAccepted) {
                shouldAskConfirmation = true;
            } else if (apiField === 'rewardable-hours' && !this.isSubmitted) {
                shouldAskConfirmation = true;
            } else if (apiField === 'cost-state-hours' && !this.isAccepted) {
                shouldAskConfirmation = true;
            } else {
                this.doActualUpdate();
            }

            if (shouldAskConfirmation) {
                // @ts-ignore
                this.$dialog.confirm('Weet je zeker dat je dit wilt aanpassen?', {
                    okText: 'Ja',
                    cancelText: 'Nee',
                }).then(() => {
                    this.doActualUpdate();
                }).catch(() => {
                    this.projectNumberClient = this.timeTracking.projectNumberClient;
                    this.status = this.timeTracking.status;
                    this.rewardableHoursStart = this.timeTracking.rewardableHours.start?.format('HH:mm') || null;
                    this.rewardableHoursEnd = this.timeTracking.rewardableHours.end?.format('HH:mm') || null;
                    this.rewardableHoursBreak = this.timeTracking.rewardableHours.break?.format('HH:mm') || null;
                    this.costStateHoursStart = this.timeTracking.costStateHours.start?.format('HH:mm') || null;
                    this.costStateHoursEnd = this.timeTracking.costStateHours.end?.format('HH:mm') || null;
                    this.costStateHoursBreak = this.timeTracking.costStateHours.break?.format('HH:mm') || null;
                    this.$forceUpdate();
                    this.updateRewardableTotal();
                    this.updateCostStateTotal();
                });
            }
        }
    }

    private hasChanged() {
        return this.status !== this.timeTracking.status
            || this.projectNumberClient !== this.timeTracking.projectNumberClient
            || this.rewardableHoursStart !== this.timeTracking.rewardableHours.start?.format('HH:mm')
            || this.rewardableHoursEnd !== this.timeTracking.rewardableHours.end?.format('HH:mm')
            || this.rewardableHoursBreak !== this.timeTracking.rewardableHours.break?.format('HH:mm')
            || this.costStateHoursStart !== this.timeTracking.costStateHours.start?.format('HH:mm')
            || this.costStateHoursEnd !== this.timeTracking.costStateHours.end?.format('HH:mm')
            || this.costStateHoursBreak !== this.timeTracking.costStateHours.break?.format('HH:mm');
    }

    private doActualUpdate() {
        const rewardableHoursBreak = parseInt(this.rewardableHoursBreak?.split(':')[0] || '0') * 3600 + parseInt(this.rewardableHoursBreak?.split(':')[1] || '0') * 60;
        const costStateHoursBreak = parseInt(this.costStateHoursBreak?.split(':')[0] || '0') * 3600 + parseInt(this.costStateHoursBreak?.split(':')[1] || '0') * 60;
        HttpService.instance.post(`/time-trackings/${this.timeTracking.id}/edit`, {
            time_tracking: {
                project_number_client: this.projectNumberClient,
                rewardable_hours_start_time: this.rewardableHoursStart + ':00',
                rewardable_hours_end_time: this.rewardableHoursEnd + ':00',
                rewardable_hours_break: rewardableHoursBreak,
                cost_state_start_time: this.costStateHoursStart + ':00',
                cost_state_end_time: this.costStateHoursEnd + ':00',
                cost_state_break: costStateHoursBreak,
            }
        }).then((response: AxiosResponse) => {
            this.updateDataFromResponse(response);
        });
    }

    acceptHours() {
        HttpService.instance.post(`/time-trackings/${this.timeTracking.id}/next-phase`)
            .then((response: AxiosResponse) => {
                this.updateDataFromResponse(response);
            });
    }

    yesDelete() {
        HttpService.instance.post(`/time-trackings/${this.timeTracking.id}/delete`).then(() => this.deleted = true);
    }

    hardDelete() {
        HttpService.instance.post(`/time-trackings/${this.timeTracking.id}/hard-delete`).then(() => this.deleted = true);
    }

    doNothing() {
    }

    updateRewardableHours(event: any, field: string) {
        if (field === 'start') {
            this.rewardableHoursStart = event.target.value;
        } else if (field === 'end') {
            this.rewardableHoursEnd = event.target.value;
        }
        this.updateRow('rewardable-hours');
    }

    updateCostStateHours(event: any, field: string) {
        if (field === 'start') {
            this.costStateHoursStart = event.target.value;
        } else if (field === 'end') {
            this.costStateHoursEnd = event.target.value;
        }
        this.updateRow('cost-state-hours');
    }

    updateRewardableTotal() {
        if (this.rewardableHoursStart !== null && this.rewardableHoursEnd !== null) {
            const breakUnix = parseInt(this.rewardableHoursBreak?.split(':')[0] || '0') * 3600 + parseInt(this.rewardableHoursBreak?.split(':')[1] || '0') * 60;
            const diff = moment(this.rewardableHoursEnd, 'HH:mm').diff(moment(this.rewardableHoursStart, 'HH:mm'), 'seconds') - breakUnix;
            let hours = Math.floor(diff / 3600);
            const minutes = Math.floor((diff - hours * 3600) / 60);
            if (hours < 0) {
                hours += 24;
            }
            this.rewardableTotal = ((hours < 10 ? '0' : '') + hours) + ':' + ((minutes < 10 ? '0' : '') + minutes);
            this.updateDifferenceTotal();
        } else {
            this.rewardableTotal = '';
        }
    }

    updateCostStateTotal() {
        if (this.costStateHoursStart !== null && this.costStateHoursEnd !== null) {
            const breakUnix = parseInt(this.costStateHoursBreak?.split(':')[0] || '0') * 3600 + parseInt(this.costStateHoursBreak?.split(':')[1] || '0') * 60;
            const diff = moment(this.costStateHoursEnd, 'HH:mm').diff(moment(this.costStateHoursStart, 'HH:mm'), 'seconds') - breakUnix;
            let hours = Math.floor(diff / 3600);
            const minutes = Math.floor((diff - hours * 3600) / 60);
            if (hours < 0) {
                hours += 24;
            }
            this.costStateTotal = ((hours < 10 ? '0' : '') + hours) + ':' + ((minutes < 10 ? '0' : '') + minutes);
            this.updateDifferenceTotal();
        } else {
            this.costStateTotal = '';
        }
    }

    get isSuperAdmin() {
        return [151, 152, 394].includes(AuthService.instance.user!.id);
    }

    get isAdmin() {
        return AuthService.instance.user?.role === 'admin' || AuthService.instance.user?.role === 'office';
    }

    get isPlanner() {
        return AuthService.instance.user?.role === 'planner';
    }

    get isOpen() {
        return TimeTrackingStatuses.isOpen(this.timeTracking.status);
    }

    get isSubmitted() {
        return TimeTrackingStatuses.isSubmitted(this.timeTracking.status);
    }

    get isAccepted() {
        return TimeTrackingStatuses.isAccepted(this.timeTracking.status);
    }

    get isChecked() {
        return TimeTrackingStatuses.isChecked(this.timeTracking.status);
    }

    get isBilled() {
        return TimeTrackingStatuses.isBilled(this.timeTracking.status);
    }

    get isInProgress() {
        return TimeTrackingStatuses.isInProgress(this.timeTracking.status);
    }

    get isMissing() {
        return TimeTrackingStatuses.isMissing(this.timeTracking.status);
    }

    statusFromKey() {
        return getTimeTrackingByStatus(this.timeTracking.status)
    }

    getBackgroundColor() {
        return `bg-${getTimeTrackingByStatus(this.timeTracking.status).color}`;
    }

    private updateDataFromResponse(response: AxiosResponse) {
        let data = response.data.data;
        if (Array.isArray(data)) {
            data = data[0];
        }

        this.timeTracking.status = Object.prototype.hasOwnProperty.call(data, 'status') ? data.status : this.timeTracking.status;
        this.timeTracking.projectNumberClient = data.project_number_client;
        this.timeTracking.rewardableHours.start = moment(data.rewardable_hours_start_time, 'HH:mm:ss');
        this.timeTracking.rewardableHours.end = moment(data.rewardable_hours_end_time, 'HH:mm:ss');
        this.timeTracking.rewardableHours.break = moment.utc(data.rewardable_hours_break * 1000);
        this.timeTracking.costStateHours.start = moment(data.cost_state_start_time, 'HH:mm:ss');
        this.timeTracking.costStateHours.end = moment(data.cost_state_end_time, 'HH:mm:ss');
        this.timeTracking.costStateHours.break = moment.utc(data.cost_state_break * 1000);

        this.projectNumberClient = data.project_number_client;
        this.status = Object.prototype.hasOwnProperty.call(data, 'status') ? data.status : this.timeTracking.status;

        this.rewardableHoursStart = moment(data.rewardable_hours_start_time, 'HH:mm:ss').format('HH:mm');
        this.rewardableHoursEnd = moment(data.rewardable_hours_end_time, 'HH:mm:ss').format('HH:mm');
        const hours = Math.floor(data.rewardable_hours_break / 3600);
        const minutes = Math.floor((data.rewardable_hours_break % 3600) / 60);
        this.rewardableHoursBreak = ((hours < 10 ? '0' : '') + hours) + ':' + ((minutes < 10 ? '0' : '') + minutes);

        this.costStateHoursStart = moment(data.cost_state_start_time, 'HH:mm:ss').format('HH:mm');
        this.costStateHoursEnd = moment(data.cost_state_end_time, 'HH:mm:ss').format('HH:mm');
        const costStateHours = Math.floor(data.cost_state_break / 3600);
        const costStateMinutes = Math.floor((data.cost_state_break % 3600) / 60);
        this.costStateHoursBreak = ((costStateHours < 10 ? '0' : '') + costStateHours) + ':' + ((costStateMinutes < 10 ? '0' : '') + costStateMinutes);

        this.updateRewardableTotal();
        this.updateCostStateTotal();
    }

    private updateDifferenceTotal() {
        if (this.costStateHoursStart !== null && this.costStateHoursEnd !== null && this.rewardableHoursStart !== null && this.rewardableHoursEnd !== null) {
            const breakUnix = parseInt(this.costStateHoursBreak?.split(':')[0] || '0') * 3600 + parseInt(this.costStateHoursBreak?.split(':')[1] || '0') * 60;
            const diff = moment(this.costStateHoursEnd, 'HH:mm').diff(moment(this.costStateHoursStart, 'HH:mm'), 'seconds') - breakUnix;

            const rewardableBreakUnix = parseInt(this.rewardableHoursBreak?.split(':')[0] || '0') * 3600 + parseInt(this.rewardableHoursBreak?.split(':')[1] || '0') * 60;
            const rewardableDiff = moment(this.rewardableHoursEnd, 'HH:mm').diff(moment(this.rewardableHoursStart, 'HH:mm'), 'seconds') - rewardableBreakUnix;

            const positive = (diff - rewardableDiff) >= 0;
            const totalDiff = Math.abs(diff - rewardableDiff);
            let hours = Math.floor(totalDiff / 3600);
            const minutes = Math.floor((totalDiff - hours * 3600) / 60);
            if (hours < 0) {
                hours += 24;
            }
            this.differenceTotal = (positive ? '+' : '-') + (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
        }
    }
}
