import Permission from "@/App/Entities/Permissions/Permission";
import AuthService from "@/App/Services/AuthService";
import GroupRouteContainer from "@/components/GroupRouteContainer.vue";
import { RedirectOption, Route, RouteConfig } from "vue-router";

export function GuestRoute(path: string, name: string, component: any): RouteConfig {
    return {
        name: name,
        path: path,
        component: component,
        async beforeEnter(to: Route, from: Route, next: Function) {
            const isLoggedIn = await AuthService.instance.check();

            if (isLoggedIn) {
                return next({ name: 'dashboard' });
            }

            return next();
        },
    };
}

export function Route(path: string, name: string, component: any, permissions?: Array<Permission> | false, meta: any = {}): RouteConfig {
    meta.permissions = permissions;
    return {
        name: name,
        path: path,
        component: component,
        meta,
        async beforeEnter(to: Route, from: Route, next: Function) {
            if (permissions != null) {
                return ensurePermissions(to, from, next, permissions);
            }

            return next();
        },
    };
}

// If permissions === false, treat permissions as disabled
export function checkPermissions(permissions?: Array<Permission> | false) {
    if (permissions == false) {
        return true;
    }

    const userPermissions: { [subject: string]: Array<string> } = AuthService.instance.permissions;

    if (userPermissions.hasOwnProperty('all') && userPermissions.all.includes('manage')) {
        return true;
    }

    if (permissions !== undefined && permissions.length > 0) {
        for (const permission of permissions) {
            if (userPermissions.hasOwnProperty(permission.subject_class)) {
                for (const action of userPermissions[permission.subject_class]) {
                    if (permission.action.includes(action) || action === 'manage' && permission.action.includes('read')) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
}

export function LogoutRoute(path: string, name: string): RouteConfig {
    return {
        name: name,
        path: path,
        async beforeEnter(to: Route, from: Route, next: Function) {
            const isLoggedIn = await AuthService.instance.check();

            if (isLoggedIn) {
                await AuthService.instance.logout();
            }

            return next({ name: 'auth.login' });
        },
    };
}

export function GroupRoute(path: string, name: string, children: RouteConfig[], component: any = null, permissions?: Array<Permission>): RouteConfig {
    return {
        name: name,
        path: path,
        children: children,
        component: component === null ? GroupRouteContainer : component,
        meta: {
            groupRoute: true,
        },
        async beforeEnter(to: Route, from: Route, next: Function) {
            if (permissions != null) {
                return ensurePermissions(to, from, next, permissions);
            }

            return next();
        },
    };
}

export function RedirectRoute(path: string, name: string, redirect: RedirectOption): RouteConfig {
    return {
        path: path,
        name: name,
        redirect: redirect,
    };
}

async function ensurePermissions(to: Route, from: Route, next: Function, permissions: Array<Permission> | false) {
    const isLoggedIn = await AuthService.instance.check();

    if (isLoggedIn) {
        if (permissions === undefined || checkPermissions(permissions)) {
            return next();
        }

        return next({ name: 'dashboard' });
    }

    return next({ name: 'auth.login' });
}
