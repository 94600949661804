

























import { Component, Vue } from "vue-property-decorator";

@Component
export default class TabsClients extends Vue {
    tabs: Vue[] = this.$children;
    detailsActive = false;

    selectTab(selectedTab: Number) {
        this.detailsActive = true;
        document.body.classList.add('hidden-overflow');
        this.tabs.forEach((tab, index) => {
            // @ts-ignore
            tab.selected = (index === selectedTab);
        });
    }
};
