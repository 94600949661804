







import { Vue } from 'vue-property-decorator';

export default class LoginLayout extends Vue {
}
