













































































import ProjectMaterial from "@/App/Entities/Projects/ProjectMaterial";
import HttpService from "@/App/Services/HttpService";
import IconClose from "@/assets/svgs/icon-close.svg";
import ProjectMaterialsTable from "@/components/materials/ProjectMaterialsTable.vue";
// @ts-ignore
import { Flashes } from 'vue-flashes';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ProjectMaterialsTable,
        IconClose,
    }
})
export default class ReportReturnMaterials extends Vue {
    @Prop({ required: false, type: Array, default: [] }) selectedMaterials!: Array<ProjectMaterial>;
    projectMaterials: ProjectMaterial[] = [];
    private loadingMaterials = true;
    private errors: Array<number> = [];
    private submittingForm: boolean = false;

    get retourButtonDisabled() {
        return this.submittingForm
            || this.loadingMaterials
            || (!this.loadingMaterials && this.projectMaterials.length === 0)
            || this.projectMaterials.filter(projectMaterial => projectMaterial.selected === true).length === 0;
    }

    async mounted() {
        await this.loadProjectMaterials();

        if (this.selectedMaterials.length > 0) {
            this.projectMaterials.forEach((projectMaterial, index) => {
                if (this.selectedMaterials.find(pM => pM.id === projectMaterial.id)) {
                    Vue.set(this.projectMaterials[index], 'selected', true)
                }
            });
        }
    }

    close() {
        this.$router.push({ name: 'project-material-management' });
    }

    async loadProjectMaterials(options?: { sortableDirection?: 'asc' | 'desc', sortableBy?: string }) {
        this.loadingMaterials = true;

        try {
            this.projectMaterials = (await HttpService.instance.get(`/projects/${this.$route.params.id}/project-materials/planned-disassemblies`, {
                sortableDirection: options?.sortableDirection ?? 'asc',
                sortableBy: options?.sortableBy ?? 'drawing',
            }))
                .data
                .data
                .map((projectMaterial: ProjectMaterial) => {
                    projectMaterial.disassemble_amount = 0;

                    return projectMaterial;
                });
        } finally {
            this.loadingMaterials = false;
        }
    }

    async submitForm() {
        this.submittingForm = true;

        if (!this.validateData()) {
            Flashes.flash('De optelsom van een regel klopt niet!', 'error', 10);
            this.submittingForm = false;

            return;
        }

        try {
            await HttpService.instance.post('/project-materials/return', {
                project_materials: this.projectMaterials
                    .filter(projectMaterial => projectMaterial.selected === true)
                    .map(projectMaterial => {
                        return {
                            id: projectMaterial.id,
                            defect_amount: projectMaterial.defect_amount,
                            return_amount: projectMaterial.return_amount,
                            lost_amount: projectMaterial.lost_amount,
                            rent_amount: projectMaterial.rent_amount,
                        };
                    }),
            });

            this.$emit('update-materials');
            this.$router.back();
        } finally {
            this.submittingForm = false;
        }
    }

    validateData() {
        this.errors = [];

        return this.projectMaterials
            .reduce((acc, projectMaterial, index) => {
                if (!projectMaterial.selected) {
                    return acc;
                }

                const valid = projectMaterial.defect_amount + projectMaterial.lost_amount + projectMaterial.return_amount + projectMaterial.rent_amount === projectMaterial.quantity;

                if (!valid) {
                    this.errors.push(index);
                }

                return acc && valid;
            }, true);
    }
}
