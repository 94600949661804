


































import { Component, Vue } from "vue-property-decorator";
import LoginLayout from "@/views/Auth/LoginLayout.vue";
import LogoIcon from '../../assets/svgs/logo.svg';
import RubyApiService from "@/App/Services/RubyApiService";

@Component({
    components: {
        LoginLayout,
        'logo-icon': LogoIcon,
    },
})
export default class PasswordReset extends Vue {
    email: string = '';

    resetPassword() {
        RubyApiService.instance.post('webapi/auth/password', {
            'email': this.email,
            'redirect_url': '/',
        }).then((value => {
            this.$router.push({ name: 'auth.password.reset.confirmation', params: { email: this.email } })
        }));
    }
}
