































































import StatusMenuCommuting from '@/components/StatusMenuCommuting.vue';
import AddCommuting from '@/components/AddCommuting.vue';
import ChangeCommuting from '@/components/ChangeCommuting.vue';
import { Component, Vue } from "vue-property-decorator";
import HttpService from "@/App/Services/HttpService";
import KilometerTracking from "@/App/Entities/Commuting/KilometerTracking";
import moment from "moment";

@Component({
    components: {
        'status-menu-commuting': StatusMenuCommuting,
        'add-commuting': AddCommuting,
        'change-commuting': ChangeCommuting,
    }
})
export default class Commuting extends Vue {
    registrations: { [id: number]: KilometerTracking } = {};
    loading: boolean = true;
    submitMode: boolean = false;
    submitting: boolean = false;
    registrationSelected: { [id: number]: boolean } = {};

    created() {
        HttpService.instance
            .get('commuting/open')
            .then(value => {
                value.data.data.forEach((tracking: KilometerTracking) => {
                    this.registrations[tracking.id] = tracking;
                });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    formatDate(date: Date) {
        moment.locale(navigator.language || 'nl');
        return moment(date).format('DD MMMM YYYY');
    }

    toggleSubmitMode() {
        const selectedIds: Array<number> = [];
        Object.keys(this.registrationSelected).forEach((value: any) => {
            if (this.registrationSelected[value]) {
                selectedIds.push(value);
            }
        });

        if (this.submitMode && selectedIds.length > 0) {
            this.submitting = true;
            HttpService.instance
                .post('commuting/submit', {
                    registrations: selectedIds,
                })
                .then((value: any) => {
                    selectedIds.forEach((value1: any) => {
                        Vue.delete(this.registrations, value1);
                    });
                })
                .finally(() => {
                    this.submitMode = !this.submitMode;
                    this.submitting = false;
                });
        } else {
            this.submitMode = !this.submitMode;
        }
    }

    keys(o: Object) {
        return Object.keys(o);
    }

    addedCommute(commute: KilometerTracking) {
        this.registrations[commute.id] = commute;
        this.$forceUpdate();
    }

    updateCommuting(commute: KilometerTracking) {
        this.registrations[commute.id] = commute;
        this.$forceUpdate();
    }

    destroyCommuting(commute: KilometerTracking) {
        Vue.delete(this.registrations, commute.id);
    }
};
