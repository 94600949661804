







import {Component, Vue} from "vue-property-decorator";
import HourControl from '@/views/hour-control/HourControl.vue';

@Component({
    components: {
        'hour-control': HourControl,
    },
})
export default class HourControlInbox extends Vue {
}
