






















import { Component, Vue } from "vue-property-decorator";
import HttpService from "../../App/Services/HttpService";
import Manual from "../../components/Manual.vue";
import InfiniteLoading from "vue-infinite-loading";

@Component({
    components: {
        'manual': Manual,
        'infinite-loading': InfiniteLoading,
    }
})
export default class ManualsOverview extends Vue {
    manuals = [];
    busy: boolean = false;
    couldLoadMore: boolean = true;
    page: number = 0;

    created() {
        this.loadMore();
    }

    infiniteHandler($state: any) {
        this.loadMore()
            .then((canLoadMore) => {
                if (canLoadMore) {
                    $state.loaded();
                } else {
                    $state.complete();
                }
            });
    }

    loadMore() {
        this.busy = true;
        this.page += 1;

        return new Promise((resolve, reject) => {
            HttpService.instance.get('/manuals', {
                page: this.page
            })
                .then(({ data }) => {
                    // @ts-ignore
                    this.manuals.push(...data.data);
                    this.couldLoadMore = data.links.next !== null;
                    resolve(this.couldLoadMore);
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }
}
