














































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class ClientContactInfo extends Vue {
    @Prop({ required: true }) readonly person!: {
        contract_hours?: string,
        client_name?: string,
        profession?: string,
        full_name: string,
        job_name: string,
        establishments?: string[],
        email: string,
        phone: string,
        mobile: string,
    };
    @Prop({ type: Boolean, default: true }) readonly fixed!: boolean;

    fields: object[] = [
        { name: 'email', displayName: 'E-mail', class: 'email', href: 'mailto: :email' },
        { name: 'phone', displayName: 'Telefoonnummer', class: 'phone', href: 'tel: :phone' },
        { name: 'mobile', displayName: 'Mobiele telefoonnummer', class: 'phone', href: 'tel: :mobile' },
        // {name: 'phone_emergency', displayName: 'Telefoonnummer bij calamiteiten', href: 'tel: :phone_emergency'},
        // {name: 'establishments', displayName: 'Vestigingen', join: ','},
        // {name: 'birthdate', displayName: 'Geboortedatum'},
        {
            name: 'address',
            displayName: 'Adresgegevens',
            class: 'place',
            href: 'https://maps.google.com/?q= :street :house_number :zip_code :city :province :country',
            format: ':street :house_number <br> :zip_code :city <br> :province :country',
        },
    ];

    hasKey(obj: object, field: string) {
        return Object.prototype.hasOwnProperty.call(obj, field);
    }

    exists(field: any) {
        if (field === null || field === undefined) {
            return false;
        }

        if (typeof field === 'string' || Array.isArray(field)) {
            if (field.toString().trim() === '') {
                return false;
            }
            return field.length > 0;
        }

        return true;
    }

    formatText(value: string | undefined, object: any) {
        if (value === undefined) {
            return '';
        }
        let words = value.split(" ");
        words = words.map(word => (word.substr(0, 1) === ":" ? object[word.substr(1)] : word))
            .filter(word => word !== '' && word !== null);
        words = words.filter(word => word !== '<br>' || words.indexOf('<br>') !== 0);
        return words.join(" ");
    }

    formatUrl(value: string | undefined, object: any, field: string) {
        if (value === undefined) {
            return '';
        }
        if (typeof object[field] === 'object') {
            object = object[field];
        }
        let words = value.split(" ");
        words = words.map(word => (word.substr(0, 1) === ":" ? object[word.substr(1)] : word));
        return words.join("%20");
    }

    @Emit('close')
    close() {
        return true;
    }
}
