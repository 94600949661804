


















































































































import StorageService from "@/App/Services/StorageService";
import ArrowLeftSvg from '@/assets/svgs/arrow-left.svg';
import ArrowRightSvg from '@/assets/svgs/arrow-right.svg';
import moment from 'moment';
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        'arrow-left-svg': ArrowLeftSvg,
        'arrow-right-svg': ArrowRightSvg,
    }
})
export default class DatePicker extends Vue {
    @Prop() initialFirstDate!: moment.Moment | undefined;
    @Prop() initialSecondDate!: moment.Moment | undefined;
    @Prop({ type: Boolean, default: false }) shouldSelectSingleDate!: Boolean;
    @Prop({ type: Boolean, default: false }) readonly saveSelection!: boolean;
    @Prop({ type: String, required: true }) readonly storagePrefix!: string;

    overlayActive = false;
    currentDate = moment();
    currentMonth = this.currentDate.month();
    firstDate: moment.Moment | null = null;
    secondDate: moment.Moment | null = null;
    shouldSetFirstDate = true;
    selectedWeek: String | null = null;
    refreshDatesToggle = false;
    hoveringWeek: String | null = null;
    restoreSelection: boolean = false;
    storageKey: string = this.storagePrefix + '-selected-dates';

    created() {
        moment.locale('nl');
        if (this.initialFirstDate !== undefined) {
            this.firstDate = this.initialFirstDate;
        }
        if (this.initialSecondDate !== undefined) {
            this.secondDate = this.initialSecondDate;
        }
        this.restoreSelection = StorageService.instance.get('previous-route') === 'project-reports-id';
        if ((this.restoreSelection || this.saveSelection) && StorageService.instance.has(this.storageKey)) {
            const dates: { firstDate: string | null, secondDate: string | null } = StorageService.instance.get(this.storageKey);
            if (dates.firstDate === null) {
                this.firstDate = null;
            } else {
                this.firstDate = moment(dates.firstDate);
            }
            if (dates.secondDate === null) {
                this.secondDate = null;
            } else {
                this.secondDate = moment(dates.secondDate);
            }
            this.dateSelected();
        } else {
            this.removeStorage();
        }
    }

    removeStorage() {
        StorageService.instance.remove(this.storageKey);
    }

    isFirstDate(date: string | null) {
        if (date?.startsWith('prev-') || date?.startsWith('next-')) {
            date = date?.substr(5);
        }
        if (this.firstDate === null) {
            return false;
        }

        return this.firstDate.format('YYYY-MM-DD') === date;
    }

    close() {
        this.overlayActive = false;
    }

    isSecondDate(date: string | null) {
        if (date?.startsWith('prev-') || date?.startsWith('next-')) {
            date = date?.substr(5);
        }
        if (this.secondDate === null) {
            return false;
        }

        return this.secondDate.format('YYYY-MM-DD') === date;
    }

    isBetweenDate(date: string | null) {
        if (date?.startsWith('prev-') || date?.startsWith('next-')) {
            date = date?.substr(5);
        }
        if (this.firstDate === null || this.secondDate === null || date === null) {
            return false;
        }

        const currentDate = moment(date, 'YYYY-MM-DD');
        return currentDate.isBetween(this.firstDate, this.secondDate);
    }

    isWeek(date: string | null) {
        if (date === null) {
            return false;
        }
        return date.startsWith('W');
    }

    dates() {
        moment.locale('nl');
        let firstOfMonth = moment(this.currentDate).startOf('month');
        let output: (string | null)[] = [];
        let i = 0;
        while (i < firstOfMonth.isoWeekday() && firstOfMonth.weekday() !== 0) {
            if (i === 0 && moment(firstOfMonth).add(i, 'days').isoWeekday() !== 1) {
                output.push('W' + firstOfMonth.format('W YYYY'));
            } else {
                const date = moment(firstOfMonth).subtract(firstOfMonth.isoWeekday() - i, 'days');
                output.push('prev-' + date.format('YYYY-MM-DD'));
            }
            i++;
        }
        const nextMonth = moment(this.currentDate).startOf('month').add(1, 'month');
        let currentDay = firstOfMonth;
        while (currentDay < nextMonth) {
            if (currentDay.isoWeekday() === 1) {
                output.push('W' + currentDay.format('W YYYY'));
            }
            output.push(currentDay.format('YYYY-MM-DD'));
            currentDay.add(1, 'day');
        }
        while (currentDay.weekday() < 6 && currentDay.weekday() > 0) {
            output.push('next-' + currentDay.format('YYYY-MM-DD'));
            currentDay.add(1, 'day');
            if (currentDay.weekday() === 6) {
                output.push('next-' + currentDay.format('YYYY-MM-DD'));
            }
        }
        return output;
    }

    formattedDate(date: string | null) {
        if (date === null) {
            return '';
        }
        if (date.startsWith('W')) {
            return date.substr(1, date.indexOf(' ') - 1);
        }
        if (date.startsWith('prev-') || date.startsWith('next-')) {
            date = date.substring(5);
        }
        return moment(date, 'YYYY-MM-DD').format('D');
    }

    selectedDate(date: string | null) {
        if (date === null) {
            return;
        } else if (date.startsWith('W')) {
            this.firstDate = moment(date.substr(1), 'W YYYY').startOf('week');
            if (this.shouldSelectSingleDate) {
                this.secondDate = this.firstDate;
            } else {
                this.secondDate = moment(this.firstDate).endOf('week');
                this.selectedWeek = date.substr(1, date.indexOf(' ') - 1);
            }
        } else {
            const clickedDate = moment(date, 'YYYY-MM-DD');

            if (this.shouldSetFirstDate) {
                this.firstDate = clickedDate;
                if (this.shouldSelectSingleDate) {
                    this.secondDate = clickedDate;
                } else {
                    this.secondDate = null;
                    this.shouldSetFirstDate = false;
                }
            } else {
                this.secondDate = clickedDate;
                this.shouldSetFirstDate = true;
                if (this.firstDate !== null && this.secondDate.isBefore(this.firstDate)) {
                    this.firstDate = this.secondDate;
                    this.secondDate = null;
                    this.shouldSetFirstDate = false;
                }
            }
        }

        this.$forceUpdate();
        this.dateSelected();
    }

    @Emit()
    dateSelected(): { from: moment.Moment | null } | { from: moment.Moment | null, to: moment.Moment | null } {
        if (this.firstDate === null && this.secondDate === null) {
            this.removeStorage();
        } else {
            StorageService.instance.set(this.storageKey, {
                firstDate: this.firstDate,
                secondDate: this.secondDate
            });
        }

        if (this.shouldSelectSingleDate) {
            return { from: this.firstDate };
        }
        return { from: this.firstDate, to: this.secondDate };
    }

    previousMonth() {
        this.currentDate = this.currentDate.subtract(1, 'month');
        this.currentMonth = this.currentDate.month();
        this.refreshDatesToggle = !this.refreshDatesToggle;
    }

    nextMonth() {
        this.currentDate = this.currentDate.add(1, 'month');
        this.currentMonth = this.currentDate.month();
        this.refreshDatesToggle = !this.refreshDatesToggle;
    }

    selectThisWeek() {
        moment.locale('nl');
        this.firstDate = moment().startOf('week');
        this.secondDate = moment().endOf('week');
        this.$forceUpdate();
        this.dateSelected();
    }

    selectLastWeek() {
        moment.locale('nl');
        this.firstDate = moment().startOf('week').subtract(1, 'week');
        this.secondDate = moment().endOf('week').subtract(1, 'week');
        this.$forceUpdate();
        this.dateSelected();
    }

    selectThisMonth() {
        moment.locale('nl');
        this.firstDate = moment().startOf('month');
        this.secondDate = moment().endOf('month');
        this.$forceUpdate();
        this.dateSelected();
    }

    selectLastMonth() {
        moment.locale('nl');
        this.firstDate = moment().startOf('month').subtract(1, 'month');
        this.secondDate = moment().endOf('month').subtract(1, 'month');
        this.$forceUpdate();
        this.dateSelected();
    }

    selectThisYear() {
        moment.locale('nl');
        this.firstDate = moment().startOf('year');
        this.secondDate = moment().endOf('year');
        this.$forceUpdate();
        this.dateSelected();
    }

    selectLastYear() {
        moment.locale('nl');
        this.firstDate = moment().startOf('year').subtract(1, 'year');
        this.secondDate = moment().endOf('year').subtract(1, 'year');
        this.$forceUpdate();
        this.dateSelected();
    }

    getStartDate() {
        if (this.firstDate === null) {
            return '';
        }
        return this.firstDate.format('DD MMM YYYY');
    }

    getEndDate() {
        if (this.secondDate === null) {
            return '';
        }
        return this.secondDate.format('DD MMM YYYY');
    }

    getWeek() {
        if (this.firstDate === null) {
            return '';
        }
        return this.firstDate.format('W');
    }

    toggleHoverWeek(date: string, mouseEnter: boolean) {
        if (!date.startsWith('W')) {
            return;
        }
        if (mouseEnter) {
            this.hoveringWeek = moment(date.substr(1), 'W YYYY').week().toString();
        } else {
            this.hoveringWeek = null;
        }
    }

    weekSelected(date: string) {
        if (date?.startsWith('prev-') || date?.startsWith('next-')) {
            date = date?.substr(5);
        }
        return this.hoveringWeek === moment(date).week().toString();
    }

    resetFilter() {
        this.firstDate = null;
        this.secondDate = null;
        this.selectedWeek = null;
        this.removeStorage();
    }

    isToday(date: string) {
        if (date?.startsWith('prev-') || date?.startsWith('next-')) {
            date = date?.substr(5);
        }
        return moment(date).isSame(moment(), 'day');
    }
}
