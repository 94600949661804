import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// @ts-ignore
import {Flashes} from 'vue-flashes';

export default class HttpService {
    private static baseUrl: string;
    public static instance = new HttpService();
    private static headers: any = {'content-type': 'application/json', 'accept': 'application/json'};
    private client: AxiosInstance;
    public static apiToken: string;

    constructor() {
        this.client = HttpService.createAxiosInstance();
    }

    get(slug: string, params: object | any = {}, headers?: any): Promise<AxiosResponse> {
        params.api_token = HttpService.apiToken;

        return this.client.get(slug, {
            params,
            headers,
        });
    }

    post(slug: string, data: object | any = {}, headers?: any, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
        data.api_token = HttpService.apiToken;

        return this.client.post(slug, data, Object.assign({
            headers,
        }, config));
    }

    put(slug: string, data: object | any = {}, headers?: any): Promise<AxiosResponse> {
        data.api_token = HttpService.apiToken;

        return this.client.put(slug, data, {
            headers,
        });
    }

    patch(slug: string, data: object | any = {}, headers?: any): Promise<AxiosResponse> {
        data.api_token = HttpService.apiToken;

        return this.client.patch(slug, data, {
            headers,
        });
    }

    delete(slug: string, headers?: any): Promise<AxiosResponse> {
        return this.client.delete(slug, {
            headers,
            data: {
                api_token: HttpService.apiToken,
            },
        });
    }

    private static createAxiosInstance(): AxiosInstance {
        const client = axios.create({
            baseURL: HttpService.baseUrl,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
        client.interceptors.response.use(
            response => response,
            (error) => {
                if (typeof error === 'undefined' || typeof error.response === 'undefined') {
                    return Promise.reject(error);
                }

                const {status} = error.response;

                if (status !== 422) {
                    return Promise.reject(error);
                }

                const errors: { [prop: string]: Array<string> } = error.response.data.errors;

                Object.values(errors).forEach((prop: Array<string>): void => {
                    prop.forEach(error => {
                        Flashes.flash(error, 'error', 10);
                    });
                });

                return Promise.reject(error);
            },
        );

        return client;
    }

    static setBaseUrl(baseUrl: string): void {
        this.baseUrl = baseUrl;

        delete this.instance.client;
        this.instance.client = this.createAxiosInstance();
    }

    static getBaseUrl(): string {
        return this.baseUrl;
    }
}
