

























































// @ts-ignore
import collapsetransition from '@ivanv/vue-collapse-transition';
// @ts-ignore
import Datepicker from 'vuejs-datepicker';
// @ts-ignore
import { en, nl } from 'vuejs-datepicker/dist/locale';
import { Component, Prop, Vue } from "vue-property-decorator";
import KilometerTracking from "@/App/Entities/Commuting/KilometerTracking";
import HttpService from "@/App/Services/HttpService";

@Component({
    components: {
        'collapsetransition': collapsetransition,
        'datepicker': Datepicker
    }
})
export default class ChangeCommuting extends Vue {
    @Prop({ type: Object }) registration !: KilometerTracking;
    changedRegistration: KilometerTracking = Object.assign({}, this.registration);
    changeCommuting = false;
    en = en;
    nl = nl;

    get totalDistance() {
        return this.changedRegistration.toDistance + this.changedRegistration.fromDistance;
    }

    destroyCommuting() {
        HttpService.instance
            .delete(`commuting/${this.registration.id}`)
            .then(value => {
                this.$emit('destroy-commuting', this.registration);
            });
    }

    updateCommuting() {
        HttpService.instance
            .patch(`commuting/${this.registration.id}`, {
                fromDistance: this.changedRegistration.fromDistance,
                toDistance: this.changedRegistration.toDistance,
                date: this.changedRegistration.date
            })
            .then(value => {
                this.changeCommuting = false;
                this.$emit('update-commuting', value.data.data);
            });
    }

    cancelUpdate() {
        this.changeCommuting = false;
        this.changedRegistration = Object.assign({}, this.registration);
    }
};
