
























import AddHours from "@/components/AddHours.vue";
import ChangeHours from "@/components/ChangeHours.vue";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import EditHours from "@/components/EditHours.vue";
import HoursRow from "@/components/HoursRow.vue";
import moment from "moment";
import WorkOrderHours from "@/components/WorkOrderHours.vue";

@Component({
    components: {
        'work-order-hours': WorkOrderHours,
        'edit-hours': EditHours,
        'change-hours': ChangeHours,
        'add-hours': AddHours,
        'hours-row': HoursRow,
    }
})
export default class HoursWrap extends Vue {
    @Prop({type: String, required: true}) readonly title !: string;
    @Prop({type: Boolean, default: true}) readonly showHeader !: boolean;
    @Prop({required: true}) workOrders !: any;
    @Prop({default: false}) readonly !: boolean;

    submitHours = false;
    selected = [];
    $refs!: {
        workOrderHours: any,
    };

    isExpired(deadlines: any) {
        deadlines = Array.isArray(deadlines) ? deadlines : [deadlines];

        for (let deadline of deadlines) {
            if (!moment(deadline).isBefore()) {
                return false;
            }
        }

        return true;
    }

    isSubmittable() {
        return this.workOrders.filter((workOrder: any) => workOrder.time_trackings.filter((timetracking: any) => {
            return timetracking.status.toString() === '0' && !timetracking.deleted;
        }).length > 0).length > 0;
    }

    addHours() {
        this.$refs.workOrderHours[0].addHours();
    }
}
