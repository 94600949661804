

























































































































import { WorkType } from "@/App/Entities/Projects/Project";
import { getTimeTrackingByStatus } from "@/App/Entities/TimeTrackingStatuses";
import AuthService from "@/App/Services/AuthService";
import ProcessService from "@/App/Services/ProcessService";
import StorageService from "@/App/Services/StorageService";
import ClearFiltersSvg from "@/assets/svgs/clear-filters.svg";
import DatePicker from "@/components/DatePicker.vue";
import Dropdown from "@/components/Dropdown.vue";
import moment from "moment";
import InfiniteLoading from 'vue-infinite-loading';
import { Component, Vue, Watch } from "vue-property-decorator";
import HttpService from "../../../App/Services/HttpService";

@Component({
    components: {
        InfiniteLoading,
        'dropdown': Dropdown,
        'datepicker': DatePicker,
        'clear-filters-svg': ClearFiltersSvg,
    },
})
export default class ProjectReportOverview extends Vue {
    filterClients: Array<{ value: number, label: string }> = [];
    costStateStatuses = [
        {
            value: 'submitted',
            label: 'Controle Planning',
        },
        {
            value: 'accepted',
            label: 'Controle administratie',
        },
        {
            value: 'checked',
            label: 'Klaar voor kostenstaat',
        },
        {
            value: 'in_progress',
            label: 'Te factureren',
        },
        {
            value: 'billed',
            label: 'Gefactureerd',
        },
    ];

    apiBase = ProcessService.getEnv('API_URL');
    apiToken = sessionStorage.getItem('apiToken');
    infiniteId: number = 1;
    currentSort: string = 'project';
    sortDirection: string = 'asc';
    busy: boolean = false;
    couldLoadMore: boolean = true;
    page: number = 0;
    totalCount: number = 0;

    searchQuery: string = '';
    firstDate: moment.Moment | null = null;
    secondDate: moment.Moment | null = null;

    projects: any[] = [];
    selectedStatus: any[] = [];
    selectedClients: any[] = [];
    selectedWorkTypes: WorkType[] = [];
    workTypes: Array<{ value: WorkType, label: string }> = [
        { value: WorkType.hours, label: 'Uren' },
        { value: WorkType.inCharge, label: 'Regie' },
        { value: WorkType.adopted, label: 'Aangenomen' },
    ];

    $refs !: {
        datepickerFilter: DatePicker,
        statusFilter: Dropdown,
        clientFilter: Dropdown,
        workTypeFilter: Dropdown,
    };

    created() {
        HttpService.instance.get('clients').then(value => {
            this.filterClients = [];
            for (const key in value.data.data) {
                this.filterClients.push({ value: value.data.data[key].id, label: value.data.data[key].name });
            }
        });

        if (
            [
                'project-reports-id',
                'project-reports',
            ].includes(StorageService.instance.get('previous-route'))
            && StorageService.instance.has('project-reports-search-query')
        ) {
            this.searchQuery = StorageService.instance.get('project-reports-search-query');
        } else {
            StorageService.instance.remove('project-reports-search-query');
        }
    }

    get filtersAvailable() {
        return this.searchQuery !== ''
            || this.firstDate !== null
            || this.selectedClients.length > 0
            || this.selectedStatus.length > 0
            || this.selectedWorkTypes.length > 0;
    }

    loadmore() {
        this.busy = true;
        this.page += 1;

        const params = this.getQueryParams();

        return new Promise((resolve, reject) => {
            new HttpService().get('/projects/report', params)
                .then(({ data }) => {
                    this.projects.push(...data.data);
                    this.totalCount = data.total;
                    this.busy = false;
                    this.couldLoadMore = data.next_page_url !== null;
                    resolve(this.couldLoadMore);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    }

    private getQueryParams() {
        const params: any = {
            page: this.page,
            sort: this.currentSort,
            order: this.sortDirection,
            startDate: undefined,
            endDate: undefined,
            search: undefined,
            client: undefined,
            status: undefined,
            workTypes: undefined,
        };

        if (this.firstDate !== null) {
            params.startDate = this.firstDate.format('DD-MM-YYYY');
        }

        if (this.secondDate !== null) {
            params.endDate = this.secondDate.format('DD-MM-YYYY');
        }

        if (this.searchQuery !== '') {
            params.search = this.searchQuery;
        }

        if (this.selectedClients.length > 0) {
            params.client = this.selectedClients;
        }

        if (this.selectedStatus.length > 0) {
            params.status = this.selectedStatus;
        }

        if (this.selectedWorkTypes.length > 0) {
            params.workTypes = this.selectedWorkTypes;
        }

        return params;
    }

    reloadData() {
        if (this.busy) {
            return;
        }
        this.page = 0;
        this.projects = [];
        this.infiniteId += 1;
    }

    findByStatus(name: number) {
        return getTimeTrackingByStatus(name);
    }

    getCostStateUrl(projectId: number) {
        return `${this.apiBase}/project-reports/${projectId}/cost-state?api_token=${AuthService.instance.user?.apiToken}`;
    }

    infiniteHandler($state: any) {
        if (this.busy) {
            return;
        }

        this.loadmore()
            .then((canLoadMore) => {
                if (canLoadMore) {
                    $state.loaded();
                } else {
                    $state.complete();
                }
            });
    }

    resetFilters() {
        this.$refs.datepickerFilter.resetFilter();
        this.$refs.statusFilter.resetFilter();
        this.$refs.clientFilter.resetFilter();
        this.$refs.workTypeFilter.resetFilter();
        this.searchQuery = '';
        this.firstDate = null;
        this.secondDate = null;
        this.selectedClients = [];
        this.selectedStatus = [];
        this.reloadData();
    }

    sortProjects(sortable: string) {
        if (this.currentSort === sortable) {
            if (this.sortDirection === 'asc') {
                this.sortDirection = 'desc';
            } else if (this.sortDirection === 'desc') {
                this.sortDirection = 'asc';
            }
        } else {
            this.currentSort = sortable;
            this.sortDirection = 'asc';
        }

        this.reloadData();
    }

    updatedDates(value: { from: moment.Moment | null, to: moment.Moment | null }) {
        this.firstDate = value.from;
        this.secondDate = value.to;
    }

    applyFilters() {
        this.$refs.datepickerFilter.close();
        this.$refs.clientFilter.close();
        this.$refs.statusFilter.close();
        this.$refs.workTypeFilter.close();
        this.reloadData();
    }

    goToProject(id: number) {
        // @ts-ignore
        this.$router.push({
            name: 'project-reports-id',
            params: {
                projectId: id
            }
        });
    }

    @Watch('searchQuery')
    onSearch() {
        if (this.searchQuery === '') {
            StorageService.instance.remove('project-reports-search-query');
        } else {
            StorageService.instance.set('project-reports-search-query', this.searchQuery);
        }
    }
}
