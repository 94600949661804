




























import { Component, Vue } from "vue-property-decorator";
import LoginLayout from "@/views/Auth/LoginLayout.vue";
import LogoIcon from '../../assets/svgs/logo.svg';

@Component({
    components: {
        LoginLayout,
        'logo-icon': LogoIcon,
    },
})
export default class PasswordResetConfirmation extends Vue {
    email: string = '';

    created() {
        this.email = this.$route.params.email;
    }
}
