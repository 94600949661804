






























import {Component, Prop, Vue} from "vue-property-decorator";
import HamburgerSvg from '@/assets/svgs/hamburger.svg';
import ProjectSvg from '@/assets/svgs/project.svg';

declare class GraphType {
    icon: String;
    percentage: Number;
    color: String;
    total: String;
    label: String;
}

@Component({
    components: {
        'hamburger-svg': HamburgerSvg,
        'projects-svg': ProjectSvg
    }
})
export default class Graph extends Vue {
    @Prop(String) labelName!: String;
    @Prop({required: true}) data!: Array<GraphType>;

    getPercentage(percentage: string) {
        if (percentage.toLowerCase() === 'nan') {
            return 0;
        }
        return percentage;
    }

    getTotalHours(hours: string) {
        return parseFloat(hours).toFixed(2);
    }
}
