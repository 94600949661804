import { GroupRoute, GuestRoute, LogoutRoute, RedirectRoute, Route } from "@/App/Entities/Routes";
import Account from "@/views/account/Account.vue";
import Commuting from "@/views/administration/commuting/Commuting.vue";
import CommutingApproved from "@/views/administration/commuting/CommutingApproved.vue";
import CommutingSubmitted from "@/views/administration/commuting/CommutingSubmitted.vue";
import HourRegistration from "@/views/administration/hour-registration/HourRegistration.vue";
import HourReportsOverview from "@/views/administration/hour-reports/HourReportsOverview.vue";
import HourReportsOverviewSubcontractors from "@/views/administration/hour-reports/HourReportsOverviewSubcontractors.vue";
import ProjectReport from "@/views/administration/project-reports/ProjectReport.vue";
import ProjectReportOverview from "@/views/administration/project-reports/ProjectReportOverview.vue";
import WorkOrder from "@/views/administration/workorder/WorkOrder.vue";
import Login from "@/views/Auth/Login.vue";
import LoginLayout from "@/views/Auth/LoginLayout.vue";
import PasswordReset from "@/views/Auth/PasswordReset.vue";
import PasswordResetConfirmation from "@/views/Auth/PasswordResetConfirmation.vue";
import ClientContacts from "@/views/contacts/ClientContacts.vue";
import Colleagues from "@/views/contacts/Colleagues.vue";
import Freelancers from "@/views/contacts/Freelancers.vue";
import HourControl from "@/views/hour-control/HourControl.vue";
import HourControlEmployees from "@/views/hour-control/HourControlEmployees.vue";
import HourControlInbox from "@/views/hour-control/HourControlInbox.vue";
import Manuals from "@/views/management/Manuals.vue";
import Materials from "@/views/management/materials/Materials.vue";
import ManualsOverview from "@/views/manuals/ManualsOverview.vue";
import MasterLayout from "@/views/MasterLayout.vue";
import CreateAssemblyTicket from "@/views/projects/materials/popups/CreateAssemblyTicket.vue";
import CreateDisassemblyTicket from "@/views/projects/materials/popups/CreateDisassemblyTicket.vue";
import IssueMaterials from "@/views/projects/materials/popups/IssueMaterials.vue";
import ReportReturnMaterials from "@/views/projects/materials/popups/ReportReturnMaterials.vue";
import ProjectMaterialManagement from "@/views/projects/materials/ProjectMaterialManagement.vue";
import LocationSpecificRisks from "@/views/work-orders/LocationSpecificRisks.vue";
import Vue from 'vue'
import VueRouter from 'vue-router'
import Administration from '../views/administration/Administration.vue';

Vue.use(VueRouter);

const routes = [
    RedirectRoute(
        '/',
        'root',
        { name: 'dashboard' }),

    GroupRoute('/', 'auth', [
            GuestRoute(
                '/login',
                'auth.login',
                Login
            ),
            LogoutRoute(
                '/logout',
                'auth.logout'
            ),
            GuestRoute(
                'password/reset',
                'auth.password.reset',
                PasswordReset
            ),
            GuestRoute(
                'password/confirm',
                'auth.password.reset.confirmation',
                PasswordResetConfirmation
            ),
        ],
        LoginLayout),

    GroupRoute('/', '', [
            Route(
                '',
                'dashboard',
                Administration,
                false
            ),
        ],
        MasterLayout),

    GroupRoute(
        '/administration',
        'administration',
        [
            GroupRoute(
                'work-order',
                '',
                [
                    GroupRoute(
                        ':id',
                        'work-order',
                        [
                            Route(
                                '',
                                'work-order.show',
                                WorkOrder,
                                [
                                    {
                                        subject_class: 'WorkOrder',
                                        action: [
                                            'manage',
                                            'read',
                                        ]
                                    },
                                    {
                                        subject_class: 'TimeTracking',
                                        action: [
                                            'manage',
                                            'manage_subcontractor',
                                            'update',
                                            'destroy',
                                            'submit',
                                            'create',
                                            'read',
                                        ]
                                    }
                                ]
                            ),
                            Route(
                                'location-specific-risks',
                                'work-order.show.location-specific-risks',
                                LocationSpecificRisks,
                                [
                                    {
                                        subject_class: 'WorkOrder',
                                        action: [
                                            'manage',
                                            'read',
                                        ]
                                    },
                                ]
                            )
                        ]
                    )
                ]
            ),
            Route(
                'hour-registration',
                'hour-registration',
                HourRegistration,
                [
                    {
                        subject_class: 'TimeTracking',
                        action: [
                            'manage',
                            'manage_subcontractor',
                            'update',
                            'destroy',
                            'submit',
                            'create',
                            'read',
                        ]
                    }
                ]
            ),
            Route(
                'commuting',
                'commuting',
                Commuting
            ),
            Route(
                'commuting-approved',
                'commuting-approved',
                CommutingApproved
            ),
            Route(
                'commuting-submitted',
                'commuting-submitted',
                CommutingSubmitted
            ),
            GroupRoute(
                'project-reports',
                '',
                [
                    Route(
                        '',
                        'project-reports',
                        ProjectReportOverview,
                        [],
                        {
                            padding: false,
                            boxShadow: false,
                        }
                    ),
                    Route(
                        ':projectId',
                        'project-reports-id',
                        ProjectReport,
                        [],
                        {
                            padding: false,
                        }
                    )
                ]
            ),
            Route(
                'hour-reports',
                'hour-reports',
                HourReportsOverview,
                [
                    {
                        subject_class: 'TimeTracking',
                        action: [
                            'manage',
                        ]
                    }
                ],
                {
                    padding: false,
                    boxShadow: false,
                }
            ),
            Route(
                'hour-reports-subcontractors',
                'hour-reports-subcontractors',
                HourReportsOverviewSubcontractors,
                [
                    {
                        subject_class: 'TimeTracking',
                        action: [
                            'manage',
                        ]
                    }
                ],
                {
                    padding: false,
                    boxShadow: false,
                }
            )
        ],
        MasterLayout
    ),
    GroupRoute(
        '/hour-control',
        '',
        [
            Route(
                '',
                'hour-control',
                HourControl,
                [
                    {
                        subject_class: 'TimeTracking',
                        action: [
                            'manage',
                        ]
                    }
                ]
            ),
            RedirectRoute(
                'projects',
                '',
                {
                    name: 'hour-control',
                }
            ),
            Route(
                'employees',
                'hour-control-employees',
                HourControlEmployees,
                [
                    {
                        subject_class: 'TimeTracking',
                        action: [
                            'manage',
                        ]
                    }
                ]
            ),
            Route(
                'inbox/:status',
                'hour-control-inbox',
                HourControlInbox,
                [
                    {
                        subject_class: 'TimeTracking',
                        action: [
                            'manage',
                        ]
                    }
                ]
            )
        ],
        MasterLayout
    ),
    GroupRoute(
        '/account',
        '',
        [
            Route(
                '',
                'my-account',
                Account
            ),
        ],
        MasterLayout
    ),
    GroupRoute(
        '/contacts',
        '',
        [
            Route(
                'colleagues',
                'colleagues',
                Colleagues
            ),
            Route(
                'clients',
                'clients',
                ClientContacts
            ),
            Route(
                'freelancers',
                'freelancers',
                Freelancers
            ),
            RedirectRoute(
                '',
                '',
                {
                    name: 'colleagues',
                }
            ),
        ],
        MasterLayout),
    GroupRoute(
        '/management',
        '',
        [
            Route(
                '/management/manuals',
                'manuals',
                Manuals,
                [
                    {
                        subject_class: 'Manual',
                        action: [
                            'manage'
                        ],
                    },
                ]
            ),
            Route(
                '/management/materials',
                'materials',
                Materials,
                [
                    {
                        subject_class: 'Materials',
                        action: [
                            'manage'
                        ],
                    },
                ]
            )
        ],
        MasterLayout
    ),
    GroupRoute(
        '/manuals',
        '',
        [
            Route(
                '',
                'manuals-overview',
                ManualsOverview
            ),
        ],
        MasterLayout
    ),
    GroupRoute(
        '/projects',
        '',
        [
            GroupRoute(
                ':id',
                'project-detail',
                [
                    GroupRoute(
                        'materials',
                        'project-material-management',
                        [
                            Route(
                                'materiaal-uitgeven',
                                'materiaal-uitgeven',
                                IssueMaterials
                            ),
                            Route(
                                'montagebon-maken',
                                'montagebon-maken',
                                CreateAssemblyTicket
                            ),
                            Route(
                                'demontagebon-maken',
                                'demontagebon-maken',
                                CreateDisassemblyTicket
                            ),
                            Route(
                                'materiaal-retour-melden',
                                'materiaal-retour-melden',
                                ReportReturnMaterials
                            ),
                        ],
                        ProjectMaterialManagement,
                        [
                            {
                                subject_class: 'Materials',
                                action: [
                                    'manage'
                                ],
                            },
                        ]
                    )
                ]
            )
        ],
        MasterLayout
    ),
    RedirectRoute(
        '*',
        '404',
        { name: 'dashboard' }
    ),
];

export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    routes,
});
