























































// @ts-ignore
import collapsetransition from '@ivanv/vue-collapse-transition';
import {Component, Vue} from "vue-property-decorator";

@Component({
    props: {
        buttontext: String,
    },
    components: {
        'collapsetransition': collapsetransition
    }
})
export default class AddHours extends Vue {
    isOpen = false;
};
