

















































































import { Component, Vue } from 'vue-property-decorator';
import HttpService from "@/App/Services/HttpService";
import moment from "moment";
import AuthService from "@/App/Services/AuthService";

@Component
export default class Administration extends Vue {
    workOrders = [];
    selectedTab = { name: 'Vandaag', day: 0 };
    loading = true;

    get userFirstName() {
        return AuthService.instance.user!.first_name;
    }

    get tabs(): any[] {
        const tabs = [
            { name: 'Gisteren', day: -1 },
            { name: 'Vandaag', day: 0 },
            { name: 'Morgen', day: 1 },
            { name: 'Overmorgen', day: 2 },
        ];

        // If it is Friday, add monday as a tab.
        if (moment().weekday() === 4) {
            tabs.push({
                name: 'Maandag',
                day: 3,
            });
        }

        return tabs;
    }

    mounted() {
        this.selectTab();
        document.body.classList.remove('hidden-overflow');
    }

    getWorkOrders(date: string) {
        this.loading = true;
        HttpService.instance.get('/work-orders/my', { date }).then(({ data }) => this.workOrders = data).finally(() => this.loading = false);
    }

    selectTab(tab = this.selectedTab) {
        this.selectedTab = tab;
        var date = moment().add(tab.day, 'd').format('YYYY-MM-DD');
        this.getWorkOrders(date);
    }

    formatTime(input: string) {
        return moment(input, 'HH:mm:ss').format('HH:mm');
    }
};
