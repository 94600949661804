export default class LocationSpecificRiskModel {
    id: number;
    title: string;
    enabled: boolean;

    constructor(data?: any) {
        this.id = data.id;
        this.title = data.title;
        this.enabled = data.enabled ?? false;
    }
}
