











import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
    public shouldShowUpdateMessage: boolean = false;

    mounted() {
        document.addEventListener('swUpdated', this.showUpdateUI);
    }

    beforeDestroy() {
        document.removeEventListener('swUpdated', this.showUpdateUI);
    }

    showUpdateUI() {
        this.shouldShowUpdateMessage = true;
    }

    reload() {
        window.location.reload();
    }
}
