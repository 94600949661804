import axios, {AxiosInstance, AxiosResponse} from 'axios';
import ProcessService from "@/App/Services/ProcessService";

export default class RubyApiService {
    public static instance = new RubyApiService();
    private static baseUrl: string;
    private static headers: any = {'content-type': 'application/json', 'accept': 'application/json'};
    private client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: RubyApiService.baseUrl,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
    }

    get(slug: string): Promise<AxiosResponse> {
        return this.client.get(slug);
    }

    post(slug: string, data: object | null = null, headers?: any): Promise<AxiosResponse> {
        return this.client.post(slug, data, {
            headers,
        });
    }

    delete(slug: string, headers?: any): Promise<AxiosResponse> {
        return this.client.delete(slug, {
            headers,
        });
    }

    static setBaseUrl(baseUrl: string): void {
        this.baseUrl = baseUrl;

        delete this.instance.client;

        this.instance.client = axios.create({
            baseURL: RubyApiService.baseUrl,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
    }

    getSsoUrl() {
        return ProcessService.getEnv('ERP_URL') + '/users/sso_sign_in'
    }
}