






















































import { Component, Vue } from "vue-property-decorator";
import HttpService from "@/App/Services/HttpService";
import Manual from "@/components/Manual.vue";
import ManualModel from "@/App/Entities/Manuals/ManualModel";
import { AxiosResponse } from "axios";
import PaginatedResult from "@/App/Entities/PaginatedResult";

@Component({
    components: {
        'manual': Manual,
    },
})
export default class Account extends Vue {
    person = {};
    manuals: Array<ManualModel> = [];
    loading: boolean = true;
    couldLoadMore: boolean = true;
    page: number = 0;

    created() {
        HttpService.instance.get('/me').then(({ data }) => this.person = data);
        this.loadMore();
    }

    loadMore() {
        this.loading = true;
        this.page += 1;

        return new Promise((resolve, reject) => {
            HttpService.instance
                .get('/manuals', {
                    page: this.page
                })
                .then((response: AxiosResponse<PaginatedResult<ManualModel>>) => {
                    // @ts-ignore
                    this.manuals.push(...response.data.data);
                    this.couldLoadMore = response.data.links.next !== null;
                    resolve(this.couldLoadMore);
                })
                .catch(reason => {
                    reject(reason);
                })
                .finally(() => {
                    this.loading = false;
                });
        });
    }
}
