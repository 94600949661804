









































import StatusMenuCommuting from '@/components/StatusMenuCommuting.vue';
import AddCommuting from '@/components/AddCommuting.vue';
import ChangeCommuting from '@/components/ChangeCommuting.vue';
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import KilometerTracking from "@/App/Entities/Commuting/KilometerTracking";
import HttpService from "@/App/Services/HttpService";

@Component({
    components: {
        'status-menu-commuting': StatusMenuCommuting,
        'add-commuting': AddCommuting,
        'change-commuting': ChangeCommuting
    }
})
export default class CommutingApproved extends Vue {
    months: Array<{ name: string, val: number }> = [];
    currentMonth: number = moment().month();
    selectedMonth: number = moment().month();
    registrations: { [id: number]: KilometerTracking } = {};
    loading: boolean = true;

    created() {
        for (let i = 0; i < 12; i++) {
            this.months.push({
                name: moment().month(i).format('MMMM'),
                val: i,
            });
        }
        this.loadData();
    }

    keys(o: Object) {
        return Object.keys(o);
    }

    loadData() {
        this.loading = true;
        this.registrations = {};
        HttpService.instance
            .get('commuting/approved', { month: this.selectedMonth + 1 })
            .then(value => {
                value.data.data.forEach((tracking: KilometerTracking) => {
                    this.registrations[tracking.id] = tracking;
                });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    formatDate(date: Date) {
        moment.locale(navigator.language || 'nl');
        return moment(date).format('DD MMMM YYYY');
    }
};
