class ProcessService {
    private readonly _env: object;
    private readonly _envKeys: Array<string>;

    constructor() {
        this._env = process.env;
        this._envKeys = Object.keys(this._env);
    }

    getEnv(key: string, defaultValue: any = null): any {
        if (this._envKeys.includes(key)) {
            // @ts-ignore
            return this._env[key];
        }

        if (this._envKeys.includes(`VUE_APP_${key}`)) {
            // @ts-ignore
            return this._env[`VUE_APP_${key}`];
        }

        return defaultValue || null;
    }
}

export default new ProcessService();