




import HourControl from './HourControl.vue';
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        'hour-control': HourControl,
    }
})
export default class HourControlEmployees extends Vue {
}
