



































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import EditHours from "./EditHours.vue";
import moment from "moment";
import HttpService from "@/App/Services/HttpService";

@Component({
    components: {
        'edit-hours': EditHours
    }
})
export default class HoursRow extends Vue {
    @Prop() initTimeTracking!: any;
    @Prop() submitHours!: boolean;
    @Prop() workOrder!: object;
    @Prop() isExpired!: boolean;
    selected: boolean = false;

    deleted = false;
    countdown: number = 30;
    timer: number = 0;

    timeTracking = this.initTimeTracking;

    remove() {
        this.deleted = true;
        this.timeTracking.deleted = true;
        this.countdown = 30;
        this.timer = setInterval(() => {
            this.countdown--;
            if (this.countdown === 0) {
                clearInterval(this.timer);
                this.$emit('deleted')
            }
        }, 1000)
    }

    restore() {
        HttpService.instance.post(`/hour-registration/${this.timeTracking.id}/restore`).then(() => {
            this.timeTracking.deleted = false;
            this.deleted = false;
            clearInterval(this.timer);
        });
    }

    formatTime(input: string) {
        return moment(input, 'HH:mm:ss').format('HH:mm');
    }

    formatBreak(seconds: number) {
        return moment().startOf('day').add(seconds, "second").format('HH:mm');
    }

    formatDate(input: string) {
        return moment(input).format('D MMMM YYYY');
    }

    @Watch('selected')
    @Emit('input')
    selectedChanged() {
        return this.selected;
    }
}
