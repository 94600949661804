export default {
    'administration': 'Administration',
    'auth.login': 'Login',
    'auth.logout': 'Logout',
    'dashboard': 'Dashboard',
    'hour-registration': 'Hour registration',
    'hours-submitted': 'Hour registration',
    'hours-approved': 'Hour registration',
    'commuting': 'Commuting',
    'commuting-approved': 'Commuting',
    'commuting-submitted': 'Commuting',
    'hour-control': 'Hour control',
    'hour-control-employees': 'Hour control',
    'hour-control-inbox': 'Hour control',
    'project-reports': 'Project reports',
    'project-reports-id': 'Project report',
    'contacts': 'Contacts',
    'colleagues': 'Colleagues',
    'clients': 'Clients',
    'hour-reports-subcontractors': 'Hour reports subcontractors',
    'my-account': 'My account',
    'freelancers': 'Freelancers',
    'manuals': 'Manuals',
    'manuals-overview': 'Manuals',
    'projects': {
        'work_types': {
            'hours': 'Uren',
            'in_charge': 'Regie',
            'adopted': 'Aangenomen',
        },
    },
    'work-order': 'Workorder',
    'work-order.show': 'Workorder',
    'work-order.show.location-specific-risks': 'Location specific risks',
}
