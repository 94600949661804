












import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class StatusMenu extends Vue {
    @Prop() openAmount!: number;

    options = [
        {name: 'Open', route: 'open', label: 'open'},
        {name: 'Ingediend', route: 'submitted', label: 'ingediende'},
        {name: 'Goedgekeurd', route: 'accepted', label: 'goedgekeurde'},
    ];
    active = this.options[0];
};
