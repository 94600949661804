



















import { Component, Prop, Vue } from "vue-property-decorator";
// @ts-ignore
import TrashSVG from "@/assets/svgs/icon-trash.svg";
import HttpService from "@/App/Services/HttpService";
import ManualModel from "@/App/Entities/Manuals/ManualModel";

@Component({
    components: {
        'icon-trash': TrashSVG,
    }
})
export default class Manual extends Vue {
    @Prop() manual!: ManualModel;
    @Prop({ required: true, type: Boolean, default: false }) link!: boolean;
    shown = true;

    openLink() {
        if (this.link) {
            window.open(this.manual.url, '_blank');
        }
    }

    deleteManual() {
        if (window.confirm('Wil je het handboek "' + this.manual.name + '" echt verwijderen?')) {
            HttpService.instance.delete('/manuals/' + this.manual.id)
                .then(value => {
                    this.shown = false;
                })
                .catch(reason => {
                    console.error(reason);
                });
        }
    }
}
