
















































import Material from "@/App/Entities/Materials/Material";
import PaginatedResult from "@/App/Entities/PaginatedResult";
import HttpService from "@/App/Services/HttpService";
import MaterialForm from "@/views/management/materials/MaterialForm.vue";
import { AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: { MaterialForm, Form: MaterialForm }
})
export default class Materials extends Vue {
    materials: Array<Material> = [];
    loading: boolean = true;
    couldLoadMore: boolean = true;
    page: number = 0;
    material: {} = {}

    created() {
        this.loadMore();
    }

    loadMore() {
        this.loading = true;
        this.page += 1;

        return new Promise((resolve, reject) => {
            HttpService.instance
                .get('/materials', {
                    page: this.page
                })
                .then((response: AxiosResponse<PaginatedResult<Material>>) => {
                    // @ts-ignore
                    this.materials.push(...response.data.data);
                    this.couldLoadMore = response.data.links.next !== null;
                    resolve(this.couldLoadMore);
                })
                .catch(reason => {
                    reject(reason);
                })
                .finally(() => {
                    this.loading = false;
                });
        });
    }

    updateRow(data: any) {
        const materialIndex = this.materials.findIndex(m => m.id === data.id);
        Vue.set(this.materials, materialIndex, data);
        (this.$refs.materialForm as MaterialForm).hide();
    }

    insertRow(material: any) {
        this.materials.push(material);
        (this.$refs.materialForm as MaterialForm).hide();
    }

    editMaterial(material: Material) {
        const materialForm = (this.$refs.materialForm as MaterialForm);
        materialForm.show(material);
    }

    createMaterial() {
        this.editMaterial(<Material>{ name: '', total_amount: 0, defect_amount: 0, id: null });
    }
}
