


























import StatusMenu from '@/components/StatusMenu.vue';
import {Component, Vue} from "vue-property-decorator";
import HoursWrap from "@/components/HoursWrap.vue";
import HttpService from "@/App/Services/HttpService";
import moment from "moment";

@Component({
    components: {
        HoursWrap,
        StatusMenu,
    }
})
export default class HourRegistration extends Vue {
    dates: { [date: string]: any } = {};
    openAmount = 0;
    readonly = false;
    loading: boolean = true;
    status: object = {};

    created() {
        this.getWorkOrders();
    }

    getWorkOrders(status: { route: string; label: string } = {route: 'open', label: 'open'}) {
        this.status = status;
        this.loading = true;
        HttpService.instance.get(`/hour-registration/${status.route}`)
            .then(({data}) => {
                this.dates = data;
            })
            .finally(() => {
                this.loading = false;
                this.readonly = true;
                if (status.route === 'open') {
                    this.openAmount = 0;
                    Object.keys(this.dates).map((date: string) => this.openAmount += this.dates[date].length);
                    this.readonly = false;
                }
            });
    }

    formatDate(input: string, oldFormat: string, newFormat: string) {
        return moment(input, oldFormat).format(newFormat);
    }
};
