



























import { Component, Vue } from "vue-property-decorator";
import HttpService from "@/App/Services/HttpService";
import AuthService from "@/App/Services/AuthService";
import Manual from "@/components/Manual.vue";
import ManualModel from "@/App/Entities/Manuals/ManualModel";

@Component({
    components: {
        Manual,
    }
})
export default class Manuals extends Vue {
    manuals: Array<ManualModel> = [];
    file: File | undefined = undefined;
    fileName = '';
    loading = true;

    created() {
        HttpService.instance.get('/manuals')
            .then(value => {
                this.manuals = value.data.data;
            })
            .catch(reason => {
                console.error(reason);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    handleFileUpload() {
        // @ts-ignore
        this.file = this.$refs.file.files[0];

        if (this.fileName === '') {
            this.fileName = this.file?.name.substring(0, this.file?.name.lastIndexOf('.')) || '';
        }
    }

    submitFile() {
        let formData = new FormData();
        if (AuthService.instance.user !== null) {
            formData.append('api_token', AuthService.instance.user!.apiToken);
        }
        formData.append('file', this.file!);
        formData.append('name', this.fileName);

        HttpService.instance.post('/manuals/store', formData, { 'Content-Type': 'multipart/form-data' })
            .then(value => {
                // @ts-ignore
                this.manuals.push(value.data.data);
                this.file = undefined;
                this.fileName = '';
            })
            .catch((reason) => {
                console.error(reason);
            });
    }
}
