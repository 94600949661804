






































import HttpService from "@/App/Services/HttpService";
import HamburgerSvg from "@/assets/svgs/hamburger.svg";
import NavigationDesktop from "@/components/NavigationDesktop.vue";
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'navigation-desktop': NavigationDesktop,
        'hamburger-svg': HamburgerSvg,
    },
})
export default class MasterLayout extends Vue {
    photo = '';
    showNotification = false;
    notificationMessage = '';

    $refs!: {
        navigationDesktop: any,
    };

    get showPadding() {
        if (typeof this.$route.meta?.padding === 'boolean') {
            return this.$route.meta.padding;
        }
        return true;
    }

    get showBreadcrumbsBoxShadow() {
        if (typeof this.$route.meta?.boxShadow === 'boolean') {
            return this.$route.meta.boxShadow;
        }
        return true;
    }

    get breadcrumbs() {
        let breadcrumbs = this.$route.matched.filter(value => {
            return value.name !== undefined && value.name.length > 0;
        }).map((value) => ({
            path: this.$router.resolve({ name: value.name }).href,
            name: value.name,
            components: value.components,
        }));
        return breadcrumbs.filter((value, index) => breadcrumbs.findIndex((v) => {
            return v.path.replace(/\/$/g, '') === value.path.replace(/\/$/g, '')
        }) === index);
    }

    created() {
        HttpService.instance.get('/me').then(({ data }) => this.photo = data.photo ?? '/images/no-profile-picture.png');

        this.$root.$on('notify', (msg: string) => {
            this.showNotification = true;
            this.notificationMessage = msg;

            setTimeout(() => {
                this.showNotification = false;
            }, 3000);
        })
    }
};
