












































































import ProjectMaterial from "@/App/Entities/Projects/ProjectMaterial";
import ArrowSvg from "@/assets/svgs/arrow-down.svg";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'arrow-svg': ArrowSvg,
    }
})
export default class ProjectMaterialsTable extends Vue {
    @Prop({ required: false, type: Array, default: () => [] }) private errors!: Array<number>;
    @Prop({ required: true, type: String }) private uniqueName!: String;
    @Prop({ required: true, type: Array }) private projectMaterials!: ProjectMaterial[];
    @Prop({ required: true, type: Boolean }) private loadingMaterials!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) private hideCheckbox!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) private hideTotalAmount!: boolean;
    private sortableBy: string = 'drawing';
    private sortableDirection: 'asc' | 'desc' = 'asc';

    selectMaterial(projectMaterial: ProjectMaterial) {
        Vue.set(projectMaterial, 'selected', !projectMaterial.selected);
    }

    sortOn(column: string) {
        if (this.sortableBy === column) {
            this.sortableDirection = this.sortableDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortableBy = column;
        }

        this.$emit('load-project-materials', { sortableDirection: this.sortableDirection, sortableBy: this.sortableBy });
    }

    getStatusFromProjectMaterial(projectMaterial: ProjectMaterial) {
        switch (projectMaterial.status) {
            default:
            case 0:
                return 'Concept';
            case 10:
                return 'Gepland';
            case 20:
                return 'In huur';
            case 30:
                return 'Geretourneerd';
            case 40:
                return 'In huur (te laat)';
        }
    }

    hasError(index: number) {
        return this.errors.includes(index);
    }
}
