


































































// @ts-ignore
import collapsetransition from '@ivanv/vue-collapse-transition';
// @ts-ignore
import Datepicker from 'vuejs-datepicker';
// @ts-ignore
import { en, nl } from 'vuejs-datepicker/dist/locale';
import { Component, Prop, Vue } from "vue-property-decorator";
import HttpService from "@/App/Services/HttpService";

@Component({
    components: {
        'collapsetransition': collapsetransition,
        'datepicker': Datepicker,
    }
})
export default class AddCommuting extends Vue {
    @Prop({ type: Boolean, default: true }) showButton!: Boolean;
    @Prop() readonly buttontext!: String;
    isOpen = false;
    en = en;
    nl = nl;

    fromDistance: number = 0;
    toDistance: number = 0;
    date = new Date();
    error: { [key: string]: string } | null = null;
    submitting: boolean = false;

    get totalDistance() {
        return this.fromDistance + this.toDistance;
    }

    submitCommuting() {
        this.submitting = true;
        HttpService.instance
            .post('commuting', {
                fromDistance: this.fromDistance,
                toDistance: this.toDistance,
                date: this.date,
            })
            .then(value => {
                this.isOpen = false;

                setTimeout(() => {
                    this.fromDistance = 0;
                    this.toDistance = 0;
                    this.date = new Date();
                }, 300);

                this.$emit('added-commute', value.data.data);
            })
            .catch(reason => {
                console.error(reason);
                this.error = reason.data;
            })
            .finally(() => {
                this.submitting = false;
            });
    }
};
