var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"app-overlay",class:{'active': _vm.overlayActive},on:{"click":function($event){_vm.overlayActive = false}}}),_c('div',{attrs:{"id":"datepicker-main"}},[_c('div',{staticClass:"select-box",class:{
            'active': this.firstDate !== null
         },on:{"click":function($event){_vm.overlayActive = !_vm.overlayActive}}},[_c('div',{staticClass:"column"},[(_vm.firstDate === null)?_c('div',{staticClass:"empty-select"},[_vm._v(" Datum ")]):_vm._e(),(_vm.firstDate !== null)?_c('div',{staticClass:"week"},[_vm._v(" Week "+_vm._s(_vm.getWeek())+" ")]):_vm._e(),_c('div',{staticClass:"dates"},[_vm._v(" "+_vm._s(_vm.getStartDate())+" "),(_vm.secondDate !== null)?_c('span',[_vm._v(" - "+_vm._s(_vm.getEndDate())+" ")]):_vm._e()])]),_c('div',{staticClass:"arrow"})]),_c('div',{staticClass:"overlay",class:_vm.overlayActive ? 'active' : ''},[(!_vm.shouldSelectSingleDate)?_c('div',{staticClass:"option-list"},[_c('div',{staticClass:"this-week",on:{"click":function($event){return _vm.selectThisWeek()}}},[_vm._v(" Deze week ")]),_c('div',{staticClass:"last-week",on:{"click":_vm.selectLastWeek}},[_vm._v(" Vorige week ")]),_c('div',{staticClass:"this-month",on:{"click":_vm.selectThisMonth}},[_vm._v(" Deze maand ")]),_c('div',{staticClass:"last-month",on:{"click":_vm.selectLastMonth}},[_vm._v(" Vorige maand ")]),_c('div',{staticClass:"this-year",on:{"click":_vm.selectThisYear}},[_vm._v(" Dit jaar ")]),_c('div',{staticClass:"last-year",on:{"click":_vm.selectLastYear}},[_vm._v(" Vorig jaar ")])]):_vm._e(),_c('div',{staticClass:"calendar-wrapper"},[_c('div',{staticClass:"title"},[_c('div',{key:_vm.currentMonth,staticClass:"month"},[_vm._v(" "+_vm._s(_vm.currentDate.format('MMMM YYYY'))+" ")]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"previous-month",on:{"click":_vm.previousMonth}},[_c('arrow-left-svg')],1),_c('button',{staticClass:"next-month",on:{"click":_vm.nextMonth}},[_c('arrow-right-svg')],1)])]),_c('div',{staticClass:"calendar"},[_vm._m(0),_c('ul',{key:_vm.refreshDatesToggle,staticClass:"dates-grid"},_vm._l((_vm.dates()),function(date){return _c('li',{key:date,class:{
                            'disabled': date === null,
                            'first': _vm.isFirstDate(date),
                            'second': _vm.isSecondDate(date),
                            'between': _vm.isBetweenDate(date),
                            'week': _vm.isWeek(date),
                            'hoveringWeek': _vm.weekSelected(date),
                            'today': _vm.isToday(date),
                            'previous-month': date.startsWith('prev-') || date.startsWith('next-'),
                        },on:{"mouseover":function($event){return _vm.toggleHoverWeek(date, true)},"mouseleave":function($event){return _vm.toggleHoverWeek(date, false)},"click":function($event){$event.preventDefault();return _vm.selectedDate(date)}}},[_vm._v(" "+_vm._s(_vm.formattedDate(date))+" ")])}),0)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"header"},[_c('li',[_vm._v("WEEK")]),_c('li',[_vm._v("MON")]),_c('li',[_vm._v("TUE")]),_c('li',[_vm._v("WED")]),_c('li',[_vm._v("THU")]),_c('li',[_vm._v("FRI")]),_c('li',[_vm._v("SAT")]),_c('li',[_vm._v("SUN")])])}]

export { render, staticRenderFns }