import App from '@/App.vue';
import AuthPlugin from "@/App/Plugins/AuthPlugin";
import AuthService from "@/App/Services/AuthService";
import HttpService from "@/App/Services/HttpService";
import ProcessService from "@/App/Services/ProcessService";
import RubyApiService from "@/App/Services/RubyApiService";
import StorageService from "@/App/Services/StorageService";
import AcceptedStatusSvg from '@/assets/svgs/status-accepted.svg';
import CheckedStatusSvg from '@/assets/svgs/status-checked.svg';
import InProgressStatusSvg from '@/assets/svgs/status-in-progress.svg';
import MissingStatusSvg from '@/assets/svgs/status-missing.svg';
import OpenStatusSvg from '@/assets/svgs/status-open.svg';
import SubmittedStatusSvg from '@/assets/svgs/status-submitted.svg';
import router from '@/router';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import moment from "moment";
import Vue from 'vue'
import vueDebounce from 'vue-debounce';
// @ts-ignore
import VueFlashes from 'vue-flashes';
// @ts-ignore
import vueScrollTo from 'vue-scroll-to';
// @ts-ignore
import VuejsDialog from 'vuejs-dialog';
// @ts-ignore
import vSelect from "vue-select";

import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import i18n from './i18n';
import './registerServiceWorker';

Vue.config.productionTip = false;

HttpService.setBaseUrl(ProcessService.getEnv('API_URL'));
RubyApiService.setBaseUrl(ProcessService.getEnv('ERP_URL'));

Vue.component('open-status-svg', OpenStatusSvg);
Vue.component('missing-status-svg', MissingStatusSvg);
Vue.component('submitted-status-svg', SubmittedStatusSvg);
Vue.component('accepted-status-svg', AcceptedStatusSvg);
Vue.component('checked-status-svg', CheckedStatusSvg);
Vue.component('in-progress-status-svg', InProgressStatusSvg);
Vue.component("v-select", vSelect);

Vue.use(VuejsDialog);
Vue.use(VueFlashes);
Vue.use(vueDebounce);
Vue.use(AuthPlugin);
Vue.use(vueScrollTo);

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://9a909dc6f78e4889bdffdc27d277efb3@sentry.io/4237614',
        integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    });
}

Vue.mixin({
    methods: {
        goToRuby(uri: string, newTab = false) {
            const form = document.createElement('form');
            document.body.appendChild(form);
            form.method = 'post';
            form.action = process.env.VUE_APP_ERP_URL + '/users/sso_sign_in';
            form.target = newTab ? '_blank' : '_self';
            const fields = {
                email: AuthService.instance.user!.email,
                token: AuthService.instance.user!.ssoToken,
                return_uri: uri.startsWith('/') ? uri : '/' + uri,
            };
            Object.entries(fields)
                .forEach(([key, value]) => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                });
            form.submit();
        }
    },
    mounted() {
        // @ts-ignore
        if (this.$route != null && this.$route.matched.length > 0 && this.$route.matched[this.$route.matched.length - 1] != null) {
            // @ts-ignore
            if (this.$route.matched[this.$route.matched.length - 1].name === this.$route.name) {
                // @ts-ignore
                StorageService.instance.set('previous-route', this.$route.name);
            }
        }
    },
});

moment.locale(navigator.language);

const root = new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app');
