export const openStatus = {
    id: 0,
    key: 'open',
    color: 'blue-darker',
    label: 'Open uren',
};

export const missingStatus = {
    id: 6,
    key: 'missing',
    color: 'blue-dark',
    label: 'Missende uren',
};

export const submittedStatus = {
    id: 1,
    key: 'submitted',
    color: 'blue-light',
    label: 'Controle planning',
};

export const acceptedStatus = {
    id: 2,
    key: 'accepted',
    color: 'green-darker',
    label: 'Controle administratie',
};

export const checkedStatus = {
    id: 3,
    key: 'checked',
    color: 'green-dark',
    label: 'Klaar voor kostenstaat',
};

export const inProgressStatus = {
    id: 5,
    key: 'in_progress',
    color: 'green',
    label: 'Te factureren',
};

export const billedStatus = {
    id: 4,
    key: 'billed',
    color: 'yellow',
    label: 'Gefactureerd',
};

export const timeTrackingStatuses = [
    openStatus,
    missingStatus,
    submittedStatus,
    acceptedStatus,
    checkedStatus,
    inProgressStatus,
    billedStatus,
];

export const filterableStatuses = [
    openStatus,
    missingStatus,
    submittedStatus,
    acceptedStatus,
    checkedStatus,
    inProgressStatus,
];

export function getTimeTrackingByStatus(status: number) {
    if (status === 0) {
        return openStatus;
    } else if (status === 1) {
        return submittedStatus;
    } else if (status === 2) {
        return acceptedStatus;
    } else if (status === 3) {
        return checkedStatus;
    } else if (status === 4) {
        return billedStatus;
    } else if (status === 5) {
        return inProgressStatus;
    } else if (status === 6) {
        return missingStatus;
    }
    return openStatus;
}

export default class TimeTrackingStatuses {
    static open = openStatus;
    static missing = missingStatus;
    static submitted = submittedStatus;
    static accepted = acceptedStatus;
    static checked = checkedStatus;
    static inProgress = inProgressStatus;
    static billed = billedStatus;

    static isOpen(status: number) {
        return status === 0;
    }

    static isSubmitted(status: number) {
        return status === 1;
    }

    static isAccepted(status: number) {
        return status === 2;
    }

    static isChecked(status: number) {
        return status === 3;
    }

    static isBilled(status: number) {
        return status === 4;
    }

    static isInProgress(status: number) {
        return status === 5;
    }

    static isMissing(status: number) {
        return status === 6;
    }
}
