export default class StorageService {
    public static instance: StorageService = new StorageService();

    get(key: string): any | null {
        const json = window.localStorage.getItem(key);

        if (json === null) {
            return null;
        }

        return JSON.parse(json);
    }

    set(key: string, value: any): void {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    remove(key: string): void {
        window.localStorage.removeItem(key);
    }

    has(key: string): boolean {
        const json = window.localStorage.getItem(key);

        return json !== null;
    }
}