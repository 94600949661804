import Vue, { DirectiveOptions, PluginObject } from 'vue';
import AuthService from '@/App/Services/AuthService';
import { DirectiveBinding } from "vue/types/options";
import { checkPermissions } from "@/App/Entities/Routes";

export default {
    install(vue: typeof Vue): void {
        vue.directive('auth', {
            inserted(el: HTMLElement, binding: DirectiveBinding) {
                const roles = Object.keys(binding.modifiers);

                AuthService.instance.check().then((isLoggedIn) => {
                    if (!isLoggedIn) {
                        el.parentNode!.removeChild(el);
                        return;
                    }

                    if (roles.length > 0 && !roles.includes(AuthService.instance.user!.role)) {
                        el.parentNode!.removeChild(el);
                    }
                });
            },
        } as DirectiveOptions);

        vue.directive('guest', {
            inserted(el: HTMLElement) {
                AuthService.instance.check().then((isLoggedIn) => {
                    if (isLoggedIn) {
                        el.parentNode!.removeChild(el);
                    }
                });
            },
        } as DirectiveOptions);

        vue.directive('permissions', {
            inserted(el: HTMLElement, binding: DirectiveBinding) {
                if (!checkPermissions(binding.value)) {
                    el.parentNode!.removeChild(el);
                }
            }
        })
    }
} as PluginObject<void>;