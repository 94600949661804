








































import Material from "@/App/Entities/Materials/Material";
import HttpService from "@/App/Services/HttpService";
import { cloneDeep } from "lodash";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {}
})

export default class MaterialForm extends Vue {
    material: Material | undefined;
    isVisible: boolean = false;
    private updating: boolean = false;

    async storeOrUpdateMaterial() {
        this.updating = true;

        try {
            const url = this.material!.id ? `/materials/${this.material!.id}` : '/materials'
            const response = await HttpService.instance.post(url, this.material)

            if (this.material!.id) {
                this.$emit('updated', response.data.data);
            } else {
                this.$emit('created', response.data.data);
            }
        } finally {
            this.updating = false;
        }
    }

    show(material: Material) {
        this.material = cloneDeep(material);
        this.isVisible = true;
    }

    hide() {
        this.material = undefined;
        this.isVisible = false;
    }
}
