import VueI18n from "vue-i18n";
import Vue from "vue";
import en from './en';
import nl from './nl';

Vue.use(VueI18n);

export default new VueI18n({
    locale: navigator.language.substr(0, 2) || document.documentElement.lang || 'nl',
    fallbackLocale: 'en',
    messages: {en, nl},
});