import moment from "moment";
import TimeTracking from "@/App/Entities/TimeTrackings/TimeTracking";
import Client from "@/App/Entities/Clients/Client";

export default interface Project {
    id: number;
    project_number: string;
    description: string;
    project_type: 1 | 0;
    establishments: string;
    client_id: number;
    contact_id: number;
    status: 0 | 1 | 2;
    start_date: moment.Moment | string | null;
    end_date: moment.Moment | string | null;
    updated_to_week: string | null;
    created_at: string;
    updated_at: string;
    craft_id: number | null;
    cost_state_status: 0 | 1 | 2;
    time_trackings: Array<TimeTracking> | undefined;
    client: Client | undefined;
    work_type: WorkType
}

export enum WorkType {
    hours = 'hours',
    inCharge = 'in_charge',
    adopted = 'adopted',
}
