



































































































import Material from "@/App/Entities/Materials/Material";
import { IssueMaterialsTab } from "@/App/Entities/Projects/ProjectMaterial";
import AuthService from "@/App/Services/AuthService";
import HttpService from "@/App/Services/HttpService";
import IconClose from "@/assets/svgs/icon-close.svg";
// @ts-ignore
import { Flashes } from 'vue-flashes';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        IconClose,
    },
})
export default class IssueMaterials extends Vue {
    autocadFile: File | null = null;
    uploading = false;
    tab: IssueMaterialsTab = IssueMaterialsTab.AUTOCAD;
    rows: { material_id: number | null, total_amount: number | null }[] = [{ material_id: null, total_amount: null }];
    materials: Material[] = [];
    drawing: string | null = null;

    $refs!: {
        amountInputs: HTMLInputElement[],
    };

    removeRow(index: number) {
        if (this.rows.length <= 1) {
            return;
        }

        this.rows.splice(index, 1);
    }

    close() {
        this.$router.push({ name: 'project-material-management' });
    }

    nextRow(event: KeyboardEvent, index: number) {
        if (event.code === 'Tab' && index === this.rows.length - 1) {
            this.addRow();
        }
    }

    async mounted() {
        this.searchMaterials();
    }

    async submitForm() {
        this.uploading = true;
        let formData = new FormData();
        if (AuthService.instance.user !== null) {
            formData.append('api_token', AuthService.instance.user!.apiToken);
        }
        formData.append('file', this.autocadFile!);

        try {
            await HttpService.instance.post('/project-materials/store-file', formData, { 'Content-Type': 'multipart/form-data' });
            this.autocadFile = null;
            this.$emit('update-materials');
            this.$router.back();
        } finally {
            this.uploading = false;
        }
    }

    mapKeyDown(map: Object, vm: Vue) {
        return Object.assign(map, {
            9: (e: Event) => {
                console.log(vm);
            },
            13: (e: Event) => {
                console.log(vm);
            },
        });
    }

    async save() {
        this.uploading = true;

        try {
            await HttpService.instance
                .post('/project-materials', {
                    project_id: this.$route.params.id,
                    materials: this.rows.map(row => {
                        return {
                            drawing: this.drawing,
                            material_id: row.material_id,
                            amount: row.total_amount,
                        };
                    }),
                });

            this.$emit('update-materials');
            this.$router.back();
        } finally {
            this.uploading = false;
        }
    }

    async searchMaterials(search?: string, loading?: Function) {
        if (loading != null) {
            loading();
        }

        try {
            this.materials = (await HttpService.instance.get('/materials', { search })).data.data.map((material: Material) => {
                return Object.assign(material, { label: `${material.name} (${material.amount_available} beschikbaar)` });
            });
        } finally {
            if (loading != null) {
                loading();
            }
        }
    }

    addRow() {
        // @ts-ignore
        this.rows.push({} as Material);
    }

    handleFileUpload(event: Event) {
        // @ts-ignore
        this.autocadFile = event.target!.files[0];
    }

    setAutocadTab() {
        this.tab = IssueMaterialsTab.AUTOCAD;
    }

    setManualTab() {
        this.tab = IssueMaterialsTab.MANUAL;
    }

    get isAutocadTab() {
        return this.tab === IssueMaterialsTab.AUTOCAD;
    }

    get isManualTab() {
        return this.tab === IssueMaterialsTab.MANUAL;
    }
}
