/* eslint-disable no-console */

import { register } from 'register-service-worker'
// @ts-ignore

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')
        },
        updated() {
            console.log('New content is available; please refresh.');
            const event = new CustomEvent('swUpdated', { detail: null })
            document.dispatchEvent(event);
            // const message: { [key: string]: string } = {
            //     en: 'New content is available. Please restart the app!',
            //     nl: 'Een nieuwe versie van de app is beschikbaar. Start de app opnieuw op om het te installeren!',
            // };
            // if (message.hasOwnProperty(navigator.language.substr(0, 2))) {
            //     Flashes.flash(message[navigator.language.substr(0, 2)], 'info', 10);
            // } else {
            //     Flashes.flash(message.en, 'info', 10);
            // }
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
