










































import ProjectMaterial from "@/App/Entities/Projects/ProjectMaterial";
import HttpService from "@/App/Services/HttpService";
import IconClose from "@/assets/svgs/icon-close.svg";
import ProjectMaterialsTable from "@/components/materials/ProjectMaterialsTable.vue";
import DownloadFile from 'js-file-download';
import moment from "moment/moment";
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import Datepicker from 'vuejs-datepicker';
//@ts-ignore
import { en, nl } from 'vuejs-datepicker/dist/locale';

@Component({
    components: {
        ProjectMaterialsTable,
        Datepicker,
        IconClose,
    }
})
export default class CreateAssemblyTicket extends Vue {
    @Prop({ required: false, type: Array, default: [] }) selectedMaterials!: Array<ProjectMaterial>;
    private projectMaterials: ProjectMaterial[] = [];
    private loadingMaterials = true;
    private rentDate = null;
    private assemblyDate = null;
    nl = nl;
    saving: boolean = false;

    async mounted() {
        await this.loadProjectMaterials();

        if (this.selectedMaterials.length > 0) {
            this.projectMaterials.forEach((projectMaterial, index) => {
                if (this.selectedMaterials.find(pM => pM.id === projectMaterial.id)) {
                    Vue.set(this.projectMaterials[index], 'selected', true)
                }
            });
        }
    }

    close() {
        this.$router.push({ name: 'project-material-management' });
    }

    async loadProjectMaterials(options?: { sortableDirection?: 'asc' | 'desc', sortableBy?: string }) {
        this.loadingMaterials = true;

        try {
            this.projectMaterials = (await HttpService.instance.get(`/projects/${this.$route.params.id}/project-materials`, {
                status: [0],
                sortableDirection: options?.sortableDirection ?? 'asc',
                sortableBy: options?.sortableBy ?? 'drawing'
            })).data.data;
        } finally {
            this.loadingMaterials = false;
        }
    }

    async submitForm() {
        this.saving = true;

        try {
            const materials = this.projectMaterials
                .filter(projectMaterial => projectMaterial.selected === true)
                .map(projectMaterial => {
                    return {
                        id: projectMaterial.id,
                        assemble_amount: projectMaterial.total_amount,
                    };
                });

            const data = {
                rent_date: moment(this.rentDate).format('YYYY-MM-DD'),
                assembly_date: moment(this.assemblyDate).format('YYYY-MM-DD'),
                materials,
            };
            const file = await HttpService.instance.post(`/projects/${this.$route.params.id}/assembly-ticket`, data, {}, { responseType: 'blob' });
            DownloadFile(file.data, file.headers['content-disposition'].split('filename=')[1].split(';')[0].split('"')[1]);

            this.$emit('update-materials');
            this.$router.back();
        } finally {
            this.saving = false;
        }
    }
}
