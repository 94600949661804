










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TabSingle extends Vue {
    @Prop({ type: String, required: true }) name !: String;
    @Prop({ type: String, required: false }) companyName !: String;
    @Prop({ type: String, required: false }) image !: String;
    selected: boolean = false;

    href() {
        return '#' + this.name.toLowerCase().replace(/ /g, '-');
    }
};
