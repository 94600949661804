


















































import StorageService from "@/App/Services/StorageService";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class Dropdown extends Vue {
    @Prop({ type: Boolean, default: false }) multiple!: boolean;
    @Prop({ type: Boolean, default: false }) searchable!: boolean;
    @Prop() defaultTitle!: String;
    @Prop({ required: true }) options!: Array<{ value: any, label: String }>;
    @Prop({ type: Boolean, default: false }) readonly saveSelection!: boolean;
    @Prop({ type: String, required: true }) readonly storagePrefix!: string;
    @Prop({ type: Number, default: 2 }) readonly maxPreviewItems!: number;

    shouldShowDropdown: boolean = false;
    selectedItem: { value: any, label: String } | null = null;
    selectedItems: Array<{ value: any, label: String }> = [];
    query: string = '';
    storageKey: string = this.multiple ? this.storagePrefix + '-selected-items' : this.storagePrefix + '-selected-item';
    restoreSelection: boolean = false;

    get shownOptions(): Array<{ value: any, label: String }> {
        const options = this.options.filter(option => option.label.toLowerCase().includes(this.query.toLowerCase()));

        return options.sort((x: { value: any, label: String }, y: { value: any, label: String }) => {
            const activeX = this.isOptionActive(x);
            const activeY = this.isOptionActive(y);

            return (activeX === activeY) ? 0 : activeX ? -1 : 1;
        });
    }

    created() {
        this.restoreSelection = StorageService.instance.get('previous-route') === 'project-reports-id';
        this.loadStorage();
        if (!this.restoreSelection && !this.saveSelection) {
            this.removeStorage();
        }
    }

    loadStorage() {
        if ((this.restoreSelection || this.saveSelection) && StorageService.instance.has(this.storageKey)) {
            if (this.multiple) {
                this.selectedItems = StorageService.instance.get(this.storageKey) ?? [];
            } else {
                this.selectedItem = StorageService.instance.get(this.storageKey) ?? null;
            }
        }
        this.selectionChanged();
    }

    selectItem(index: { value: any, label: String }) {
        if (this.multiple) {
            this.selectMultiple(index);
        } else {
            this.selectSingle(index);
        }
        this.selectionChanged();
    }

    setStorage() {
        if (this.multiple) {
            StorageService.instance.set(this.storageKey, this.selectedItems);
        } else {
            StorageService.instance.set(this.storageKey, this.selectedItem);
        }
    }

    removeStorage() {
        StorageService.instance.remove(this.storageKey);
    }

    selectSingle(value: { value: any, label: String }) {
        if (this.selectedItem === value) {
            this.selectedItem = null;
            this.removeStorage();
        } else {
            this.selectedItem = value;
            this.setStorage();
        }
    }

    close() {
        this.shouldShowDropdown = false;
    }

    selectMultiple(value: { value: any, label: String }) {
        const valueMap = this.selectedItems.map(item => item.value);
        if (valueMap.includes(value.value)) {
            this.selectedItems.splice(valueMap.indexOf(value.value), 1);
        } else {
            this.selectedItems.push(value);
        }
        if (this.selectedItems.length === 0) {
            this.removeStorage();
        } else {
            this.setStorage();
        }
    }

    isOptionActive(value: { value: any, label: String }) {
        if (this.multiple) {
            return this.selectedItems.map(item => item.value).includes(value.value);
        }
        return value === this.selectedItem;
    }

    get areItemsSelected() {
        if (this.multiple) {
            return this.selectedItems.length > 0;
        }
        return this.selectedItem !== null;
    }

    get previewSelectedItems() {
        if (this.maxPreviewItems === 0) {
            return this.selectedItems.length === 0 ? '' : `${this.selectedItems.length} geselecteerd`;
        }

        let preview = this.selectedItems.slice(0, this.maxPreviewItems).map(value => value.label).join(', ');

        if (this.selectedItems.length > this.maxPreviewItems) {
            preview += ` (+${this.selectedItems.length - this.maxPreviewItems})`;
        }

        return preview;
    }

    get tooltipSelectedItems() {
        return this.selectedItems.map(value => value.label).join('\n');
    }

    resetFilter() {
        StorageService.instance.remove(this.storageKey);
        this.query = '';
        this.selectedItems = [];
        this.selectedItem = null;
        this.$forceUpdate();
        this.selectionChanged();
    }

    @Emit('input')
    selectionChanged(): any[] | any {
        if (this.multiple) {
            return this.selectedItems.map((value: { value: any; label: String }) => {
                return value.value;
            });
        }
        if (this.selectedItem === null) {
            return null;
        }
        return this.selectedItem.value;
    }

    @Watch('options')
    optionsChanged() {
        this.loadStorage();
    }
}
