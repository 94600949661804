import Material from "@/App/Entities/Materials/Material";

export default interface ProjectMaterial {
    id: number;
    project_id: number;
    material_id: number;
    drawing: string;
    status: number;
    total_amount: number;
    defect_amount: number;
    return_amount: number;
    lost_amount: number;
    rent_amount: number;
    assembly_date: string | null;
    rent_date: string | null;
    created_at: string;
    updated_at: string;
    material: Material;

    disassemble_amount?: number;
    selected?: boolean;
    quantity?: number;
}

export enum IssueMaterialsTab {
    AUTOCAD,
    MANUAL,
}
