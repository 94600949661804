






































import Project from "@/App/Entities/Projects/Project";
import ProjectMaterial from "@/App/Entities/Projects/ProjectMaterial";
import HttpService from "@/App/Services/HttpService";
import ArrowSvg from '@/assets/svgs/arrow-down.svg';
import ProjectMaterialsTable from "@/components/materials/ProjectMaterialsTable.vue";
// @ts-ignore
import { Flashes } from 'vue-flashes';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ProjectMaterialsTable,
        'arrow-svg': ArrowSvg,
    }
})
export default class ProjectMaterialManagement extends Vue {
    private project: Project | {} = {};
    private projectMaterials: ProjectMaterial[] = [];
    private loadingMaterials = true;

    mounted() {
        this.loadProject();
        this.loadProjectMaterials();
    }

    get selectedMaterials() {
        return this.projectMaterials.filter(projectMaterial => projectMaterial.selected === true);
    }

    async loadProject() {
        this.project = (await HttpService.instance.get(`/projects/${this.$route.params.id}`)).data.project;
    }

    async loadProjectMaterials(options?: { sortableDirection?: 'asc' | 'desc', sortableBy?: string }) {
        this.loadingMaterials = true;

        try {
            this.projectMaterials = (await HttpService.instance.get(`/projects/${this.$route.params.id}/project-materials`, {
                sortableDirection: options?.sortableDirection ?? 'asc',
                sortableBy: options?.sortableBy ?? 'drawing'
            })).data.data;
        } finally {
            this.loadingMaterials = false;
        }
    }

    closePopup() {
        this.$router.push({ name: 'project-material-management' });
    }
}
