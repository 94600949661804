export default {
    'administration': 'Administratie',
    'auth.login': 'Login',
    'auth.logout': 'Loguit',
    'dashboard': 'Dashboard',
    'hour-registration': 'Urenregistratie',
    'hours-submitted': 'Urenregistratie',
    'hours-approved': 'Urenregistratie',
    'commuting': 'Woon-werk kilometers',
    'commuting-approved': 'Woon-werk kilometers',
    'commuting-submitted': 'Woon-werk kilometers',
    'hour-control': 'Urencontrole',
    'hour-control-employees': 'Urencontrole',
    'hour-control-inbox': 'Urencontrole',
    'project-reports': 'Projectrapportage',
    'project-reports-id': 'Projectrapportage',
    'contacts': 'Contacten',
    'colleagues': 'Collega\'s',
    'clients': 'Contactpersonen',
    'hour-reports-subcontractors': 'Urenrapportage Onderaannemers',
    'my-account': 'Mijn account',
    'freelancers': 'ZZP\'ers',
    'materials': 'Materialen',
    'manuals': 'Handboeken',
    'manuals-overview': 'Handboeken',
    'projects': {
        'work_types': {
            'hours': 'Uren',
            'in_charge': 'Regie',
            'adopted': 'Aangenomen',
        },
    },
    'work-order': 'Werkorder',
    'work-order.show': 'Werkorder',
    'work-order.show.location-specific-risks': 'Locatie specifieke risico\'s'
}
