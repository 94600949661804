























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TabsColleagues extends Vue {
    @Prop({ type: String, required: true }) search !: String;

    tabs: Vue[] = this.$children;
    detailsActive = false;

    selectTab(selectedTab: Number) {
        this.detailsActive = true;
        document.body.classList.add('hidden-overflow');
        this.tabs.forEach((tab, index) => {
            // @ts-ignore
            tab.selected = (index === selectedTab);
        });
    }

    hit() {
        return '<span class="hit">' + this.search + '</span>';
    }
};
