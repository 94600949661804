
































































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import HttpService from "@/App/Services/HttpService";
import EditHours from "@/components/EditHours.vue";
import HoursRow from "@/components/HoursRow.vue";

@Component({
    components: { HoursRow, EditHours }
})
export default class WorkOrderHours extends Vue {
    @Prop() workOrder!: object;
    @Prop() submitHours!: boolean;
    @Prop() showHeader!: boolean;
    @Prop() readonly !: boolean;

    hoursSubmitted: string[] = [];
    timeTracking = { project_number_client: '', break: 0 };
    selected: { [key: string]: boolean | undefined } = {};
    countdown = 30;
    timer: number = 0;
    $refs!: {
        editHours: any,
    };

    addHours() {
        this.$refs.editHours.isOpen = true;
    }

    isExpired(deadlines: any) {
        deadlines = Array.isArray(deadlines) ? deadlines : [deadlines];

        for (let deadline of deadlines) {
            if (!moment(deadline).isBefore()) {
                return false;
            }
        }

        return true;
    }

    remove(array: any[], element: object) {
        const index = array.indexOf(element);
        array.splice(index, 1);
        this.selected = {};
    }

    submit(workOrder: any) {
        let ids: string[] = [];
        for (let id in this.selected) {
            if (this.selected[id]) {
                ids.push(id);
            }
        }

        if (ids.length === 0) {
            return;
        }

        HttpService.instance.post('/hour-registration/submit', { ids }).then(() => {
            this.hoursSubmitted = ids;
            this.countdown = 30;
            this.$emit('submitted');
            workOrder.time_trackings.filter((e: any) => ids.includes(e.id.toString())).map((e: any) => {
                e.updated_at = moment().toISOString();
                e.status = 1;
            });
            this.timer = setInterval(() => {
                this.countdown--;
                if (this.countdown === 0) {
                    clearInterval(this.timer);
                    this.selected = {};
                    this.hoursSubmitted = [];
                }
            }, 1000);
        })
    }

    reverseSubmit(workOrder: any) {
        HttpService.instance.post('/hour-registration/reverse', { ids: this.hoursSubmitted }).then(() => {
            workOrder.time_trackings.filter((e: any) => this.hoursSubmitted.includes(e.id.toString())).map((e: any) => {
                e.status = 0;
            });
            clearInterval(this.timer);
            this.hoursSubmitted = [];
        });
    }

    get canSubmitHours(): boolean {
        const keys: Array<string> = Object.keys(this.selected);

        return keys.filter((key: string) => {
            return this.selected[key] === true;
        }).length > 0;
    }
}
