


































































































































import WorkOrderModel from "@/App/Entities/WorkOrders/WorkOrder";
import AuthService from "@/App/Services/AuthService";
import HttpService from "@/App/Services/HttpService";
import AddHoursSvg from "@/assets/svgs/icon-add-hours.svg";
import AddHours from "@/components/AddHours.vue";
import ClientContactInfo from "@/components/ClientContactInfo.vue";
import HoursWrap from "@/components/HoursWrap.vue";
// @ts-ignore
import { CollapseTransition } from "@ivanv/vue-collapse-transition/src/index";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        'collapsetransition': CollapseTransition,
        'client-contact-info': ClientContactInfo,
        'hours-wrap': HoursWrap,
        'add-hours': AddHours,
        'add-hours-svg': AddHoursSvg
    }
})
export default class WorkOrder extends Vue {
    workOrder = {};
    loading: boolean = true;
    contactPopoverActive = false;
    $refs!: {
        hoursWrap: any,
    };

    mounted() {
        HttpService.instance.get('/work-orders/' + this.$route.params.id)
            .then(({ data }) => this.workOrder = data.data)
            .finally(() => this.loading = false)
    }

    get workOrderDescription() {
        return (this.workOrder as WorkOrderModel)
            .description
            .trim()
            .replace(/(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm, match => `<a target="_blank" href="${match}">${match}</a>`)
            .replace(/\n/g, '<br>');
    }

    get useCollapseContact() {
        return window.innerWidth <= 992;
    }

    addHours() {
        this.$refs.hoursWrap.addHours();
    }

    formatDate(date: string) {
        return moment(date).format('dddd D MMMM Y')
    }

    formatTime(input: string) {
        return moment(input, 'HH:mm:ss').format('HH:mm');
    }

    formatString(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    formatBytes(bytes: number, decimals: number = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    shortenString(string: string, length: number) {
        return string.substring(0, length) + (string.length > length ? "..." : "");
    }

    openGreenCard(vehicle: any) {
        window.open(`${process.env.VUE_APP_API_URL}/vehicles/${vehicle.id}/green-card?api_token=${AuthService.instance.user?.apiToken}`, '_blank');
    }
};
