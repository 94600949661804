



































import StatusMenuCommuting from '@/components/StatusMenuCommuting.vue';
import AddCommuting from '@/components/AddCommuting.vue';
import ChangeCommuting from '@/components/ChangeCommuting.vue';
import { Component, Vue } from "vue-property-decorator";
import HttpService from "@/App/Services/HttpService";
import KilometerTracking from "@/App/Entities/Commuting/KilometerTracking";
import moment from "moment";

@Component({
    components: {
        'status-menu-commuting': StatusMenuCommuting,
        'add-commuting': AddCommuting,
        'change-commuting': ChangeCommuting
    }
})
export default class CommutingSubmitted extends Vue {
    registrations: { [id: number]: KilometerTracking } = {};
    loading: boolean = true;

    created() {
        HttpService.instance
            .get('commuting/submitted')
            .then(value => {
                value.data.data.forEach((tracking: KilometerTracking) => {
                    this.registrations[tracking.id] = tracking;
                });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    keys(o: Object) {
        return Object.keys(o);
    }

    formatDate(date: Date) {
        moment.locale(navigator.language || 'nl');
        return moment(date).format('DD MMMM YYYY');
    }
};
