




































































































































































































import Client from "@/App/Entities/Clients/Client";
import ProjectReportModel from "@/App/Entities/ProjectReports/ProjectReportModel";
import Project from "@/App/Entities/Projects/Project";
import TimeTracking from "@/App/Entities/TimeTrackings/TimeTracking";
import TimeTrackingStatuses, { getTimeTrackingByStatus } from "@/App/Entities/TimeTrackingStatuses";
import AuthService from "@/App/Services/AuthService";
import HttpService from "@/App/Services/HttpService";
import ProcessService from "@/App/Services/ProcessService";
import { AxiosResponse } from "axios";
import moment from "moment";
import { objectToFormData } from "object-to-formdata";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProjectReport extends Vue {
    apiUrl: string = ProcessService.getEnv('API_URL');
    erpUrl: string = ProcessService.getEnv('ERP_URL');

    customer: Client | null = null;
    project: Project | null = null;
    timeTrackings: Array<TimeTracking> = [];
    reportVersions: Array<any> = [];
    allTimeTrackingsSelected: boolean = false;
    selectedTimeTrackings: Array<number> = [];
    minimumStatus: number = 9;
    exportTimeTrackingSlotMap: { [key: number]: number } = {};

    manDayStateWeek: string | undefined = undefined;
    manDayWeeks: Array<string> = [];

    apiToken!: string;
    orderBy: string = 'date';
    orderDesc: boolean = false;

    get costStateUrl() {
        if (this.project === null) {
            return '';
        }

        const formDataObject: any = {
            api_token: HttpService.apiToken,
            timeTrackings: this.selectedTimeTrackings,
        };

        // @ts-ignore
        const urlParams = new URLSearchParams(objectToFormData(formDataObject));

        return `${this.apiUrl}/project-reports/${this.project.id}/cost-state?${urlParams}`;
    }

    beforeMount() {
        this.apiToken = HttpService.apiToken;
        this.loadData();
    }

    loadData() {
        this.exportTimeTrackingSlotMap = [];
        this.manDayWeeks = [];
        HttpService.instance.get(`/projects/${this.$route.params.projectId}`,
            {
                order: this.orderBy,
                orderDirection: this.orderDesc ? 'desc' : 'asc',
            })
            .then((response: AxiosResponse<ProjectReportModel>) => {
                const data = response.data;

                this.project = data.project;

                if (typeof this.project.start_date === 'string') {
                    this.project.start_date = moment(this.project.start_date);
                }

                if (typeof this.project.end_date === 'string') {
                    this.project.end_date = moment(this.project.end_date);
                }

                this.timeTrackings = data.timeTrackings;
                this.customer = data.project.client!;

                for (const timeTracking of this.timeTrackings) {
                    if (timeTracking.timeTrackingSlots.length > 0) {
                        this.exportTimeTrackingSlotMap[timeTracking.id] = timeTracking.timeTrackingSlots[0].id;
                        if (timeTracking.status < this.minimumStatus) {
                            this.minimumStatus = timeTracking.status;
                        }

                        const formattedWeek = moment(timeTracking.date).format('WW YYYY');
                        if (!this.manDayWeeks.includes(formattedWeek)) {
                            this.manDayWeeks.push(formattedWeek);
                        }
                    }
                }
                this.manDayStateWeek = this.manDayWeeks[0];
            })
            .catch((reason: any) => {
            });
    }

    get isOffice() {
        return AuthService.instance.user?.role === 'office';
    }

    get isBilledButtonActive() {
        if (this.selectedTimeTrackings.length === 0) {
            return false;
        }

        const timeTrackings = this.selectedTimeTrackings.length > 0
            ? this.selectedTimeTrackings.map(selectedId => this.timeTrackings.find(timeTracking => timeTracking.id === selectedId))
            : this.timeTrackings;

        const minStatus = timeTrackings
            .map(value => value!.status)
            .filter((value, index, self) => {
                // Get only the unique statuses
                return self.indexOf(value) === index;
            })
            .sort()[0];

        return [
            TimeTrackingStatuses.billed.id,
            TimeTrackingStatuses.inProgress.id,
        ].includes(minStatus);
    }

    goToCustomer() {
        window.open(`${this.erpUrl}/opdrachtgevers/${this.customer!.id}`, '_self');
    }

    selectTimeTracking(id: number) {
        if (this.selectedTimeTrackings.includes(id)) {
            this.selectedTimeTrackings.splice(this.selectedTimeTrackings.indexOf(id), 1);
            this.allTimeTrackingsSelected = false;
        } else {
            this.selectedTimeTrackings.push(id);
            if (this.selectedTimeTrackings.length === this.timeTrackings.length) {
                this.allTimeTrackingsSelected = true;
            }
        }
    }

    formatDate(date: string, format: string, inputFormat?: string) {
        if (inputFormat !== undefined) {
            return moment(date, inputFormat).format(format);
        }
        return moment(date).format(format);
    }

    getTimeTrackingRate(timeTracking: TimeTracking) {
        if (timeTracking.timeTrackingSlots.length > 0) {
            return timeTracking.timeTrackingSlots[0].rate.toFixed(2)
                .replace('.', ',');
        }
        return '-';
    }

    getTimeTrackingTotalPrice(timeTracking: TimeTracking) {
        if (timeTracking.timeTrackingSlots.length > 0) {
            return timeTracking.timeTrackingSlots[0].amount.toFixed(2)
                .replace('.', ',');
        }
        return '-';
    }

    getTimeTrackingStatus(status: number) {
        return getTimeTrackingByStatus(status);
    }

    billedButton() {
        if (this.isBilledButtonActive) {
            if (this.selectedTimeTrackings.length > 0) {
                if (confirm('Weet je zeker dat je de geselecteerde items op gefactureerd wil zetten?')) {
                    HttpService.instance
                        .post(`/project-reports/${this.project!.id}/billed`, {
                            timeTrackings: this.selectedTimeTrackings,
                        })
                        .then(() => {
                            this.timeTrackings.forEach((timeTracking) => {
                                if (this.selectedTimeTrackings.includes(timeTracking.id)) {
                                    timeTracking.status = 4;
                                }
                            });
                            this.confirmBilled('Uren zijn op gefactureerd gezet');
                        })
                }
            } else if (confirm('Weet je zeker dat je dit project op gefactureerd wil zetten?')) {
                HttpService.instance
                    .post(`/project-reports/${this.project!.id}/billed`, {})
                    .then(() => {
                        this.timeTrackings.forEach((timeTracking) => {
                            timeTracking.status = 4;
                        });
                        this.confirmBilled('Project is op gefactureerd gezet');
                    });
            }
        }
    }

    confirmBilled(msg: string) {
        this.$root.$emit('notify', msg);
    }

    exportFile(extension: 'csv' | 'xlsx') {
        let exportableTimeTrackings: Array<number>;
        if (this.selectedTimeTrackings.length === 0) {
            exportableTimeTrackings = this.timeTrackings.map(value => value.id);
        } else {
            exportableTimeTrackings = this.selectedTimeTrackings;
        }

        let checkedBoxes = '';
        exportableTimeTrackings.forEach((value: number) => {
            checkedBoxes += `checked_boxes[]=${this.exportTimeTrackingSlotMap[value]}&`;
        });
        if (extension === 'csv') {
            window.open(encodeURI(`${this.erpUrl}/projectrapportage/${this.project!.id}/export?${checkedBoxes}sort=user&direction=asc`), '_blank');
        } else {
            window.open(encodeURI(`${this.erpUrl}/projectrapportage/${this.project!.id}/export-${extension}?${checkedBoxes}sort=user&direction=asc`), '_blank');
        }
    }

    openManDayState() {
        if (this.manDayWeeks.length > 0) {
            // @ts-ignore This is a mixin
            this.goToRuby(`projectrapportage/${this.project!.id}/mandagstaat?week=${moment(this.manDayStateWeek, 'WW YYYY').format('DD-MM-YYYY')}`, true);
            console.log(`${this.erpUrl}/projectrapportage/${this.project!.id}/mandagstaat?week=${this.manDayStateWeek}`);
        }
    }

    goToProject() {
        window.open(`${this.erpUrl}/projecten/${this.project!.id}`, '_self');
    }

    selectAll() {
        this.allTimeTrackingsSelected = !this.allTimeTrackingsSelected;
        if (this.allTimeTrackingsSelected) {
            this.selectedTimeTrackings = this.timeTrackings.map(value => {
                return value.id;
            });
        } else {
            this.selectedTimeTrackings = [];
        }
    }

    sortTimeTrackings(columnName: string) {
        if (this.orderBy === columnName) {
            this.orderDesc = !this.orderDesc;
        } else {
            this.orderDesc = false;
        }
        this.orderBy = columnName;
        this.loadData();
    }
}
