

































import StatusMenuContacts from '@/components/StatusMenuContacts.vue';
import TabsColleagues from "@/components/TabsColleagues.vue";
import TabSingle from '@/components/TabSingle.vue';
import { Component, Vue } from "vue-property-decorator";
import HttpService from "@/App/Services/HttpService";
import InfiniteLoading from "vue-infinite-loading";
import ClientContactInfo from "@/components/ClientContactInfo.vue";

@Component({
    components: {
        ClientContactInfo,
        'status-menu-contacts': StatusMenuContacts,
        'tabs-colleagues': TabsColleagues,
        'tab-single': TabSingle,
        'infinite-loading': InfiniteLoading
    }
})
export default class Colleagues extends Vue {
    search = '';
    colleagues: Array<any> = [];
    page = 1;

    async getColleagues() {
        return HttpService.instance.get('/employees', {
            search: this.search,
            page: this.page
        }).then(({ data }) => data);
    }

    searchColleagues() {
        this.colleagues = [];
        this.page = 1;
        // @ts-ignore
        this.$refs.infiniteLoading.stateChanger.reset();
    }

    closeTab(index: number) {
        document.body.classList.remove('hidden-overflow');
        // @ts-ignore
        this.$refs['colleagues' + index][0].selected = false;
        // @ts-ignore
        this.$refs.tabColleagues.detailsActive = false;
    }

    infiniteHandler($state: any) {
        this.getColleagues().then((response) => {
            response.data.map((colleague: any) => this.colleagues.push(colleague));

            if (response.data.length === 0) {
                return $state.complete();
            }
            $state.loaded();

            this.page++;
        });
    }
};
