














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class StatusMenuCommuting extends Vue {
    @Prop({ type: Number, default: 0 }) count!: number;
};
