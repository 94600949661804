


























































































































































import { Component, Vue } from "vue-property-decorator";
import Dropdown from "@/components/Dropdown.vue";
import DatePicker from "@/components/DatePicker.vue";
import moment from "moment";
import HttpService from "@/App/Services/HttpService";
import InfiniteLoading from "vue-infinite-loading";
import ClearFiltersSvg from "@/assets/svgs/clear-filters.svg";
import { objectToFormData } from "object-to-formdata";

@Component({
    components: {
        'dropdown': Dropdown,
        'datepicker': DatePicker,
        'infinite-loading': InfiniteLoading,
        'clear-filters-svg': ClearFiltersSvg,
    }
})
export default class HourReportsOverviewSubcontractors extends Vue {
    firstDate: moment.Moment | null = null;
    secondDate: moment.Moment | null = null;
    selectedClients: any[] = [];
    selectedProjects: any[] = [];
    selectedSubcontractors: any[] = [];
    filterClients: Array<{ value: number, label: string }> = [];
    filterProjects: Array<{ value: number, label: string }> = [];
    filterSubcontractors: Array<{ value: number, label: string }> = [];
    currentSort: string = 'project';
    sortDirection: string = 'asc';
    infiniteId: number = 1;
    busy: boolean = false;
    couldLoadMore: boolean = true;
    page: number = 0;
    totalCount: number = 0;
    hours: any[] = [];
    selectAllHours: boolean = false;
    selectedHours: any = {};
    selectedCount: number = 0;

    $refs !: {
        datepickerFilter: any,
        clientFilter: any,
        projectFilter: any,
        subcontractorFilter: any,
    };

    created() {
        HttpService.instance.get('clients').then(value => {
            this.filterClients = [];
            for (const key in value.data.data) {
                this.filterClients.push({ value: value.data.data[key].id, label: value.data.data[key].name });
            }
        });
        HttpService.instance.get('projects').then(value => {
            this.filterProjects = [];
            for (const key in value.data.data) {
                this.filterProjects.push({ value: value.data.data[key].id, label: value.data.data[key].name });
            }
        });
        HttpService.instance.get('subcontractors').then(value => {
            this.filterSubcontractors = [];
            for (const key in value.data.data) {
                this.filterSubcontractors.push({
                    value: value.data.data[key].id,
                    label: value.data.data[key].name
                });
            }
        });
    }

    get countSelected() {
        if (this.selectedCount > 0) {
            return this.selectedCount;
        }
        return this.totalCount;
    }

    selectRow(id: number) {
        this.selectedHours[id] = !this.selectedHours[id];
        this.changedSelection();
    }

    changedSelection() {
        let checked = 0;
        for (const selectedHour in this.selectedHours) {
            if (this.selectedHours[selectedHour]) {
                checked++;
            }
        }
        this.selectedCount = checked;
        this.selectAllHours = checked === this.hours.length;
    }

    allHoursToggled() {
        for (const selectedHour in this.selectedHours) {
            this.selectedHours[selectedHour] = this.selectAllHours;
        }
        this.selectedCount = this.selectAllHours ? this.hours.length : 0;
    }

    formatDate(date: string) {
        return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }

    getTimeType(timeType: number) {
        switch (timeType) {
            case 1:
                return 'Vrij';
            case 2:
                return 'Ziek';
            case 3:
                return 'Feestdag';
            case 0:
            default:
                return 'Project';
        }
    }

    reloadData() {
        if (this.busy) {
            return;
        }
        this.page = 0;
        this.hours = [];
        this.infiniteId += 1;
        this.loadMore();
    }

    loadMore() {
        this.busy = true;
        this.page += 1;

        return new Promise((resolve, reject) => {
            new HttpService().get('/hour-reports/subcontractors', this.buildFilterParams())
                .then(({ data }) => {
                    this.hours.push(...data.data);
                    data.data.map((value: any) => {
                        this.selectedHours[value.id] = false;
                    });
                    this.totalCount = data.total;
                    this.busy = false;
                    this.couldLoadMore = data.next_page_url !== null;
                    resolve(this.couldLoadMore);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    }

    buildFilterParams() {
        const params: { endDate: undefined | string; page: number; sort: string; projects: undefined | any[]; subcontractors: undefined | any[]; clients: undefined | any[]; startDate: undefined | string; order: string } = {
            page: this.page,
            sort: this.currentSort,
            order: this.sortDirection,
            startDate: undefined,
            endDate: undefined,
            clients: undefined,
            subcontractors: undefined,
            projects: undefined,
        };

        if (this.firstDate !== null) {
            params.startDate = this.firstDate.format('DD-MM-YYYY');
        }

        if (this.secondDate !== null) {
            params.endDate = this.secondDate.format('DD-MM-YYYY');
        }

        if (this.selectedClients.length > 0) {
            params.clients = this.selectedClients;
        }

        if (this.selectedProjects.length > 0) {
            params.projects = this.selectedProjects;
        }

        if (this.selectedSubcontractors.length > 0) {
            params.subcontractors = this.selectedSubcontractors;
        }

        return params;
    }

    exportHours(format: string) {
        const ids = [];
        for (const selectedHour in this.selectedHours) {
            if (this.selectedHours[selectedHour]) {
                ids.push(selectedHour);
            }
        }
        let link = HttpService.getBaseUrl() + '/hour-reports/subcontractors/export-' + format + '?';
        const formDataObject: any = {
            floot: 1,
            api_token: HttpService.apiToken,
            checked_boxes: ids,
        };

        if (ids.length === 0) {
            formDataObject.start_date = this.firstDate?.format('DD-MM-YYYY');
            formDataObject.end_date = this.secondDate?.format('DD-MM-YYYY');

            if (this.selectedClients.length > 0) {
                formDataObject.clients = this.selectedClients;
            }

            if (this.selectedProjects.length > 0) {
                formDataObject.projects = this.selectedProjects;
            }

            if (this.selectedSubcontractors.length > 0) {
                formDataObject.subcontractors = this.selectedSubcontractors;
            }
        }
        // @ts-ignore
        link += (new URLSearchParams(objectToFormData(formDataObject)));
        window.open(link, '_blank');
    }

    infiniteHandler($state: any) {
        this.loadMore()
            .then((canLoadMore) => {
                if (canLoadMore) {
                    $state.loaded();
                } else {
                    $state.complete();
                }
            });
    }

    sortHours(sortable: string) {
        if (this.currentSort === sortable) {
            if (this.sortDirection === 'asc') {
                this.sortDirection = 'desc';
            } else if (this.sortDirection === 'desc') {
                this.sortDirection = 'asc';
            }
        } else {
            this.currentSort = sortable;
            this.sortDirection = 'asc';
        }

        this.reloadData();
    }

    get filtersAvailable() {
        return this.firstDate !== null
            || this.selectedClients.length > 0
            || this.selectedProjects.length > 0
            || this.selectedSubcontractors.length > 0;
    }

    applyFilters() {
        this.reloadData();
    }

    updatedDates(value: { from: moment.Moment | null, to: moment.Moment | null }) {
        this.firstDate = value.from;
        this.secondDate = value.to;
    }

    resetFilters() {
        this.firstDate = null;
        this.secondDate = null;
        this.selectedSubcontractors = [];
        this.selectedProjects = [];
        this.selectedClients = [];
        this.$refs.clientFilter.resetFilter();
        this.$refs.datepickerFilter.resetFilter();
        this.$refs.projectFilter.resetFilter();
        this.$refs.subcontractorFilter.resetFilter();
        this.reloadData();
    }
}
