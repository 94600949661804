import StorageService from "@/App/Services/StorageService";
import RubyApiService from "@/App/Services/RubyApiService";
import LoginResponse from "@/App/Entities/Auth/LoginResponse";
import { AxiosResponse } from "axios";
import HttpService from "@/App/Services/HttpService";

export default class AuthService {
    public static instance: AuthService = new AuthService;

    get user(): LoginResponse | null {
        return StorageService.instance.get('auth') as LoginResponse;
    }

    get permissions(): { [subject: string]: Array<string> } {
        return StorageService.instance.get('permissions');
    }

    public async check(): Promise<boolean> {
        const user: LoginResponse | null = this.user;

        if (user === null) {
            return false;
        }

        const response: AxiosResponse = await RubyApiService.instance.post(
            'webapi/auth/check',
            {
                sso_token: user.ssoToken,
            },
            {
                client: user.client,
                'access-token': user.accessToken,
                uid: user.userId,
            }
        );

        if (response.data.logged_in === true) {
            HttpService.apiToken = this.user!.apiToken;
        }

        return response.data.logged_in;
    }

    public login(username: string, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            RubyApiService.instance.post('webapi/auth/sign_in', {
                email: username,
                password: password,
            }).then((response: AxiosResponse) => {
                StorageService.instance.set('auth', {
                    id: response.data.id,
                    client: response.headers.client,
                    tokenType: response.headers['token-type'],
                    accessToken: response.headers['access-token'],
                    userId: response.headers.uid,
                    expiry: response.headers.expiry,
                    ssoToken: response.data.sso_token,
                    apiToken: response.data.api_token,
                    role: response.data.role.name,
                    is_subcontractor: response.data.role.is_subcontractor,
                    avatar: response.data.photo_thumb_url === 'null' ? null : response.data.photo_thumb_url,
                    first_name: response.data.first_name,
                    infix: response.data.infix,
                    last_name: response.data.last_name,
                    email: response.data.email,
                });
                HttpService.apiToken = response.data.api_token;
                HttpService.instance.get('permissions', {})
                    .then((value) => {
                        const permissions: { [key: string]: Array<string> } = {};
                        for (const permission of value.data.data) {
                            if (!permissions.hasOwnProperty(permission.subject_class)) {
                                permissions[permission.subject_class] = [];
                            }
                            permissions[permission.subject_class].push(permission.action);
                        }
                        StorageService.instance.set('permissions', permissions)
                    })
                    .finally(() => {
                        resolve();
                    });
            }).catch((reason) => {
                reject(reason);
            });
        });
    }

    public async logout(): Promise<void> {
        const user: LoginResponse = this.user!;

        await RubyApiService.instance.delete('webapi/auth/sign_out', {
            client: user.client,
            'access-token': user.accessToken,
            uid: user.userId,
        });

        StorageService.instance.remove('auth');
        StorageService.instance.remove('permissions');
    }

    private erpRedirect(uri: string | null) {
        const form = document.createElement('form');
        document.body.appendChild(form);
        form.method = 'post';
        form.action = RubyApiService.instance.getSsoUrl();
        const fields = {
            email: this.user!.userId,
            token: this.user!.ssoToken,
            return_uri: uri,
        };
        Object.entries(fields)
            .forEach(([key, value]) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = value ?? '';
                form.appendChild(input);
            });
        form.submit();
        throw new Error('don\'t redirect yet');
    }
}
