

































import LocationSpecificRiskModel from "@/App/Entities/WorkOrders/LocationSpecificRiskModel";
import WorkOrderModel from "@/App/Entities/WorkOrders/WorkOrder";
import HttpService from "@/App/Services/HttpService";
// @ts-ignore
import { Flashes } from 'vue-flashes';
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LocationSpecificRisks extends Vue {
    private loadingCounter = 2;
    workOrder: WorkOrderModel | null = null;

    locationSpecificRisks: LocationSpecificRiskModel[] = []

    mounted() {
        HttpService.instance.get('/work-orders/' + this.$route.params.id)
            .then(({ data }) => this.workOrder = data.data)
            .finally(() => this.loadingCounter--);

        HttpService.instance.get('/work-orders/' + this.$route.params.id + '/location-specific-risks')
            .then(({ data }) => {
                this.locationSpecificRisks = data.map((risk: any) => {
                    return new LocationSpecificRiskModel(risk);
                });
            })
            .finally(() => this.loadingCounter--);
    }

    submitRisks() {
        HttpService.instance
            .post('/work-orders/' + this.$route.params.id + '/location-specific-risks', {
                locationSpecificRisks: this.locationSpecificRisks
            })
            .then(value => {
                Flashes.flash('Opgeslagen', 'info', 10);
            });
    }

    get loading() {
        return this.loadingCounter > 0;
    }
}
