


















































































































































































































































































































































































































import moment from 'moment';
import TimeTrackingStatuses from '@/App/Entities/TimeTrackingStatuses';
import { Component, Vue } from "vue-property-decorator";
import ArrowSvg from '@/assets/svgs/arrow-down.svg';
import DashboardSvg from '@/assets/svgs/dashboard.svg';
import CollapseSvg from '@/assets/svgs/collapse.svg';
import UncollapseSvg from '@/assets/svgs/uncollapse.svg';
import PlanningSvg from '@/assets/svgs/planning.svg';
import ProjectsSvg from '@/assets/svgs/projects.svg';
import CalculatorSvg from '@/assets/svgs/calculator.svg';
import UserSvg from '@/assets/svgs/user.svg';
import GearSvg from '@/assets/svgs/gear.svg';
import BookSvg from '@/assets/svgs/icon-book.svg';
import ContactsSvg from '@/assets/svgs/contacts.svg';
import CollapsedLogoSvg from '@/assets/svgs/collapsed-logo.svg';
import LogoSvg from '@/assets/svgs/logo.svg';
import AdministrationSvg from '@/assets/svgs/administration.svg';
import ClockSvg from '@/assets/svgs/clock.svg';
import { checkPermissions } from "@/App/Entities/Routes";
import { RawLocation } from "vue-router";
import HttpService from "@/App/Services/HttpService";

@Component({
    components: {
        'arrow-svg'         : ArrowSvg,
        'dashboard-svg'     : DashboardSvg,
        'collapse-svg'      : CollapseSvg,
        'uncollapse-svg'    : UncollapseSvg,
        'planning-svg'      : PlanningSvg,
        'projects-svg'      : ProjectsSvg,
        'calculator-svg'    : CalculatorSvg,
        'user-svg'          : UserSvg,
        'gear-svg'          : GearSvg,
        'book-svg'          : BookSvg,
        'contacts-svg'      : ContactsSvg,
        'collapsed-logo-svg': CollapsedLogoSvg,
        'logo-svg'          : LogoSvg,
        'administration-svg': AdministrationSvg,
        'clock-svg'         : ClockSvg,
    },
})
export default class NavigationDesktop extends Vue {
    env: any = process.env;
    toggleCollapsedClass: boolean = false;
    statusCount: Object = {};
    menuCollapsed: boolean = true;
    planningActive: boolean = false;
    employeesActive: boolean = false;
    managementActive: boolean = false;
    manualsActive: boolean = false;
    calculationActive: boolean = false;
    administrationActive: boolean = false;
    hourControlActive: boolean = false;
    contactsActive: boolean = false;
    timeTrackingCounts: {
        open?: number,
        submitted?: number,
        accepted?: number,
        checked?: number,
        billed?: number,
        in_progress?: number,
        missing?: number;
    } = {};
    private infix: string = '';
    private firstName: string = '';
    private lastName: string = '';

    get isHourControlActive() {
        return this.hourControlActive
               || this.isRouteActive('hour-control')
               || this.isRouteActive('hour-control-employees')
               || this.isRouteActive('hour-control-inbox');
    }

    get openStatus() {
        return TimeTrackingStatuses.open.key;
    }

    get missingStatus() {
        return TimeTrackingStatuses.missing.key;
    }

    get submittedStatus() {
        return TimeTrackingStatuses.submitted.key;
    }

    get acceptedStatus() {
        return TimeTrackingStatuses.accepted.key;
    }

    get checkedStatus() {
        return TimeTrackingStatuses.checked.key;
    }

    get inProgressStatus() {
        return TimeTrackingStatuses.inProgress.key;
    }

    get name() {
        return `${this.firstName} ${this.infix} ${this.lastName}`;
    }

    get showHourRegistration() {
        return this.showNavigationForRoute({ name: 'hour-registration' });
    }

    get hourRegistrationActive() {
        return ['hour-registration', 'hours-approved', 'hours-submitted'].includes(this.$route.name!);
    }

    get showIllness() {
        return false;
    }

    get currentDate() {
        return moment().format('DD-MM-YYYY');
    }

    created() {
        if (checkPermissions([{ subject_class: 'TimeTracking', action: ['manage'] }])) {
            HttpService.instance.get('time-trackings/counts').then(value => {
                this.timeTrackingCounts = value.data.data;
            });
        }
    }

    showNavigationForRoute(to: RawLocation) {
        const meta = this.$router.resolve(to).route.meta;
        if (meta?.hasOwnProperty('permissions')) {
            return checkPermissions(meta.permissions)
        }
        return false;
    }

    isRouteActive(name: string) {
        const ignoreList: { [key: string]: any[] } = {
            'administration': [
                'hour-control',
                'hour-control-employees',
                'hour-control-inbox',
            ],
        };

        for (const key of Object.keys(ignoreList)) {
            if (key === name && ignoreList[key].includes(this.$route.name)) {
                return false;
            }
        }

        for (const routeRecord of this.$route.matched) {
            if (routeRecord.name === name) {
                return true;
            }
        }
        return false;
    }
};
