












































// @ts-ignore
import collapsetransition from '@ivanv/vue-collapse-transition';
import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {
        'collapsetransition': collapsetransition
    }
})
export default class ChangeHours extends Vue {
    changeHours = false;
    // watch: {
    //     changeHours(val) {
    //         this.$emit('input', val);
    //     },
    // },
};
