












































































// @ts-ignore
import collapsetransition from '@ivanv/vue-collapse-transition';
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import HttpService from "@/App/Services/HttpService";

@Component({
    components: {
        'collapsetransition': collapsetransition
    }
})
export default class EditHours extends Vue {
    @Prop({ type: String, default: "" }) readonly buttonText !: string;
    @Prop({
        type: Object,
        default: () => ({ isOpen: false, project_number_client: '', break: 0 })
    }) timeTracking !: any;
    @Prop({ type: Object, default: () => ({}), required: true }) workOrder !: any;
    @Prop({ type: String }) start !: string;
    @Prop({ type: String }) end !: string;

    isOpen = false;
    start_time = this.formatTime(this.timeTracking.start_time || this.start);
    end_time = this.formatTime(this.timeTracking.end_time || this.end);
    project_number_client = this.timeTracking.project_number_client;
    break_time = this.timeTracking.break;

    breaks = [
        { name: 'Geen pauze', value: 0 },
        { name: '15 minuten', value: 900 },
        { name: '30 minuten', value: 1800 },
        { name: '45 minuten', value: 2700 },
        { name: '60 minuten', value: 3600 },
    ];
    times: object[] = [];
    projectNumberError: boolean = false;

    mounted() {
        var startOfDay = moment().startOf('day');
        for (let i = 0; i < 96; i++) {
            this.times.push({ name: startOfDay.format('HH:mm') });
            startOfDay = startOfDay.add(15, 'minutes')
        }
    }

    get formattedExecutionDate() {
        return moment(this.workOrder.execution_date).format('D MMMM YYYY');
    }

    formatTime(input: string) {
        return moment(input, 'HH:mm:ss').format('HH:mm');
    }

    cancelHours() {
        this.isOpen = false;
        this.projectNumberError = false;
    }

    saveHours() {
        if (this.project_number_client === '' || this.project_number_client === null) {
            this.projectNumberError = true;
            return;
        }
        if (this.timeTracking.id == null) {
            HttpService.instance.post('/hour-registration', {
                work_order_id: this.workOrder.id,
                start_time: this.start_time,
                end_time: this.end_time,
                project_number_client: this.project_number_client,
                break: this.break_time
            }).then(({ data }) => {
                this.isOpen = false;
                this.$emit('created', data)
            });
        } else {
            HttpService.instance.put(`/hour-registration/${this.timeTracking.id}`, {
                start_time: this.start_time,
                end_time: this.end_time,
                project_number_client: this.project_number_client,
                break: this.break_time
            }).then(({ data }) => {
                this.isOpen = false;
                this.$emit('input', data);
            });
        }
    }

    deleteHours() {
        HttpService.instance.delete(`/hour-registration/${this.timeTracking.id}`).then(() => {
            this.$emit('deleted');
            this.isOpen = false;
        })
    }
};
